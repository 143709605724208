import { useGoogleLogin } from "@react-oauth/google";
import React from "react";

import auth from "../../api/auth";

const LoginWithGoogle = ({ responseGoogle }) => {
  /*
    To google login
  */
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const resp = await auth.googlelogin(codeResponse.access_token);
      await responseGoogle(resp.data);
    },
  });

  return (
    <div>
      <a className="social-btn" onClick={() => login()}>
        <div className="row">
          <div className="col-3">
            <img src="/static/img/google.png" className="icon mr-10"></img>
          </div>
          <div className="col-9 text-left pl-0 align-self-center">Continue With Google</div>
        </div>
      </a>
    </div>
  );
};
export default LoginWithGoogle;
