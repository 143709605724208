import { BackTop } from "antd";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { connect, useDispatch } from "react-redux";

import ModuleCustomHead from "../../components/layouts/modules/ModuleCustomHead";
import DrawerPrimary from "../../components/shared/drawers/DrawerPrimary";
import ModuleDrawerOverlay from "../../components/shared/drawers/modules/ModuleDrawerOverlay";
import NavigationBottom from "../../components/shared/navigations/NavigationBottom";
import config from "../../config/config.json";
import { setCartItems, setWishlistTtems } from "../../store/ecomerce/action";

const MasterLayout = ({ children }) => {
  const dispatch = useDispatch();

  const [cookies] = useCookies(["cart", "compare", "wishlist"]);

  /*
    To set ecomercer parameters
  */
  function handleSetEcomercerParameters() {
    if (cookies) {
      if (cookies.cart) {
        dispatch(setCartItems(cookies.cart));
      }
      if (cookies.wishlist) {
        dispatch(setWishlistTtems(cookies.wishlist));
      }
    }
  }

  useEffect(() => {
    handleSetEcomercerParameters();

    setTimeout(function () {
      document.getElementById("__next").classList.add("loaded");
    }, 100);

    setTimeout(function () {
      document.getElementById("__next").classList.add("ps-loaded");
    }, 100);
  }, []);

  /*
    To share on whats app
  */
  function shareOnWhatsApp() {
    window.open("https://wa.me/" + config.whatsapp_no, "_blank");
  }
  return (
    <>
      <ModuleCustomHead />
      <div className="ps-page">
        {children}
        <NavigationBottom />
        <ModuleDrawerOverlay />
        <DrawerPrimary />
        <div id="loader-wrapper">
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div>
        <BackTop className="back-top">
          <button className="ps-btn--backtop">
            <i className="icon-arrow-up my-float"></i>
          </button>
        </BackTop>

        <a className="float color-white" onClick={shareOnWhatsApp}>
          <i className="fa fa-whatsapp my-float"></i>
        </a>
      </div>
    </>
  );
};

export default connect()(MasterLayout);
