import React, { useEffect } from "react";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import useEcomerce from "../../hooks/useEcomerce";
import MyAddress from "../profile/MyAddress";

const AccountAddress = () => {
  const { countrylist, getCountryList } = useEcomerce();

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "My Account",
      url: "/profile",
    },
    {
      id: 3,
      text: "Address",
    },
  ];
  useEffect(() => {
    getCountryList();
  }, []);
  return (
    <Container>
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--shopping" id="myaddress">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Address"} />
                </div>
                <div className="ps-layout__right">
                  <MyAddress
                    addressevent={"account"}
                    countrylist={countrylist}
                    setAddressValues={() => console.log("")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default AccountAddress;
