import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import config from '../config/config.json';
import { VarientKeyName, VarientName } from "../../src/pages/product/VariantKey";
import useEcomerce from './useEcomerce';
import ProductRepository from '../api/ProductRepository';
import LocalstorageParam from '../pages/common/LocalstorageParam';

const useProductHook = (product, currencyrate, ecomerce) => {
  var varientobject = new Object();
  const navigate = useNavigate();
  const { addItem, addWishlistItem, removeItem } = useEcomerce();

  const [quantity, setQuantity] = useState(1);
  const [disable, setDisable] = useState(false);
  const [productvariant, setProductvariant] = useState();
  const [isVarientobject, setVarientobject] = useState();
  const [show, setShow] = useState(false);
  const [productItem, setProductItem] = useState("");
  const [productStock, setProductStock] = useState(product ? product.stock : 0);
  const [iskeyarray, setKeyArray] = useState([]);
  const [variantArray, setVariantArray] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [isvariantname, setVariantname] = useState([]);
  const [productImage, setProductImage] = useState();

  let variantname = "";
  let checkedValue = "";

  useEffect(() => {
    varientobject["varientdisable"] = "Yes";
    setVariantKey();
    getRadios();
    setVariantName();
    setVarient();
    setVarientobject(varientobject);
  }, [isvariantname, productvariant, selectedVariant]);


  /*
  To get variantname 
  */
  const getRadios = () => {
    const allradios = document.getElementsByClassName(`radio_container_${product.id}`);

    for (let i = 0; i < allradios.length; i++) {
      const inputElements = allradios[i].querySelectorAll('input[type=radio]');
      inputElements.forEach(input => {
        if (input.checked) {
          checkedValue += input.value + "-";
        }
      });
    }
  };

  /*
  To close modal
  */
  const handleClose = () => {
    setShow(false);
  };

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To set product variant array
  */
  const setVariantKey = () => {
    if (variantname === "") {
      if (productvariant && productvariant.length > 0) {
        setKeyArray(VarientKeyName(productvariant));
      }
    }
  };

  /*
   To make variant name by selected variant  
 */
  const setVariantName = () => {
    if (iskeyarray && iskeyarray.length > 0) {
      variantname = VarientName(iskeyarray, checkedValue);
      setVariantname(variantname);
    }
    let result = "";
    var flag = false;
    if (variantname && variantname !== null && variantname !== "") {
      result = variantname.slice(0, -1);
      var j = 0;
      productvariant.map((item, index) => {
        if (item.variantname === result) {
          j = index;
          flag = true;
        }
      });

      if (flag) {
        var newProductItem = { ...productItem };
        varientobject["varientdisable"] = "Yes";
        if (productvariant[j].varianttitle && productvariant[j].varianttitle !== "" && productvariant[j].varianttitle !== null) {
          newProductItem["productname"] = productvariant[j].varianttitle;
        }
        newProductItem["price"] = productvariant[j].price;
        newProductItem["stock"] = productvariant[j].stock;
        newProductItem["offerprice"] = productvariant[j].offerprice;
        newProductItem["offertype"] = productvariant[j].offertype;
        if (productvariant[j].productimagelist.length > 0) {
          newProductItem["imagename"] = productvariant[j].productimagelist[0].imagename;
        } else {
          newProductItem["imagename"] = product.imagename || productImage;
        }
        if (Number(productvariant[j].stock) > 0) {
          varientobject["productid"] = productvariant[j].productid;
          varientobject["productvariantid"] = productvariant[j].id;
          varientobject["quantity"] = 1;
          varientobject["wishlistid"] = newProductItem.id;
          varientobject["varientdisable"] = "No";
        }
        varientobject["displaystock"] = "Yes";
        setVarientobject(varientobject);
        setProductItem(newProductItem);
      }
    }
  };

  /*
    To add item to cart
  */
  const handleAddItemToCart = async (e) => {
    varientobject = new Object();
    setVarientobject("");
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      const responseData = await ProductRepository.getProductVariant(product.uniqueid);
      if (responseData.length > 0) {
        setProductvariant(responseData);
        setVariantKey();
        setVarient(responseData);
        setProductItem(product);

        setShow(true);
        document.body.classList.remove("overflow-auto");
      } else {
        if (!disable) {
          await addItem(
            {
              productid: product.id,
              quantity: quantity,
              productvariantid: product.variantid,
            },
            ecomerce.cartItems,
            "cart"
          );
        }
      }
    } else {
      navigate("/login");
    }
  };

  /*
    To set variant array for display
 */
  const setVarient = () => {
    if (productvariant && productvariant.length > 0) {
      const variantSet = new Set();

      productvariant.forEach((item) => {
        if (item.variantjson) {
          try {
            const variantjson = JSON.parse(item.variantjson);
            Object.keys(variantjson).forEach(key => {
              const variant = { name: key, value: variantjson[key] };
              variantSet.add(JSON.stringify(variant));
            });
          } catch (error) {
            console.error("Error parsing variantjson:", error);
          }
        }
      });

      const newVariantArray = Array.from(variantSet).map(item => JSON.parse(item));
      setVariantArray(newVariantArray);
    }
  };

  const groupedVariantArray = Object.values(
    variantArray.reduce((acc, { name, value }) => {
      if (!acc[name]) {
        acc[name] = { name, values: [] };
      }
      acc[name].values.push(value);
      return acc;
    }, {})
  );

  /*
   To increase item qty
 */
  const handleIncreaseItemQty = (e) => {
    e.preventDefault();
    let qty;
    if (quantity < product.stock) {
      qty = Number(quantity) + 1;
      setQuantity(qty);
    }
  }

  /*
    To decrease item qty
  */
  const handleDecreaseItemQty = (e) => {
    e.preventDefault();
    let qty;
    if (quantity > 1) {
      qty = Number(quantity) - 1;
      setQuantity(qty);
    }
    if (qty >= 1) {
      if (Number(product.stock) >= qty) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  };

  /*
    To add to cart with variant product
  */
  const addToCart = (e) => {
    e.preventDefault();
    if (isVarientobject.varientdisable === "No") {
      addItem(
        {
          productid: isVarientobject.productid,
          quantity: 1,
          productvariantid: isVarientobject.productvariantid,
          wishlistid: isVarientobject.wishlistid,
        },
        ecomerce.cartItems,
        "cart"
      );
      setShow(false);
      handleClose();
    }
  };

  /*
    To add item to wishlist
  */
  const handleAddItemToWishlist = async (e) => {
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      const wishlistid = await addWishlistItem(
        {
          productid: product.id,
          productvariantid: "0",
        },
        ecomerce.wishlistItems,
        "wishlist"
      );
      product.alreadyinwishlist = 1;
      if (Number(wishlistid) > 0) {
        product.wishlistid = wishlistid;
      }
    } else {
      navigate("/login");
    }
  };

  /*
    To remove item from wishlist
  */
  const handleRemoveItem = (e, wishlistid) => {
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      removeItem({ wishlistid: wishlistid }, ecomerce.wishlistItems, "wishlist");
      product.alreadyinwishlist = "0";
    } else {
      navigate("/login");
    }
  };

  /*
    To set image in variable with image path
  */
  function getImageURL(product) {
    const imagepath = config.apiUrl + config.image_path;
    let imageURL = [];

    if (product.variantimagename) {
      imageURL.push(`${imagepath}${product.variantimagename}`);
    } else if (product.imagename && product.imagename !== null) {
      imageURL.push(`${imagepath}${product.imagename}`);
      imageURL = imageURL.concat(`${imagepath}${product.imagename}`);
      imageURL = imageURL.concat(`${imagepath}${product.imagename}`);
    } else {
      imageURL.push("/static/img/noproduct-images.png");
    }
    return imageURL;
  }


  /*
    To handle add to cart from Wishlist
  */
  async function handleAddItemToCartFromWishlist(e, product) {
    varientobject = new Object();
    setVarientobject("");
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      if (product.hasvariant !== null && product.hasvariant !== "" && product.hasvariant === "1") {
        setProductItem(product);
        const responseData = await ProductRepository.getProductVariant(product.uniqueid);
        if (responseData.length > 0) {
          setProductvariant(responseData);
          setVariantKey();
          setVarient(responseData);
          setProductItem(product);
          setProductImage(product.imagename);
          setShow(true);
          document.body.classList.remove("overflow-auto");
        } else {
          addItem(
            {
              productid: product.productid,
              quantity: 1,
              productvariantid: 0,
              wishlistid: product.id,
            },
            ecomerce.cartItems,
            "cart"
          );
        }
      } else {
        addItem(
          {
            productid: product.productid,
            quantity: 1,
            productvariantid: 0,
            wishlistid: product.id,
          },
          ecomerce.cartItems,
          "cart"
        );
      }
    }
    else {
      navigate("/login");
    }
  }


  return {
    quantity,
    disable,
    productvariant,
    isVarientobject,
    show,
    productItem,
    productStock,
    iskeyarray,
    variantArray,
    selectedVariant,
    isvariantname,
    groupedVariantArray,
    getRadios,
    handleClose,
    setVariantKey,
    setVariantName,
    handleAddItemToCart,
    setVarient,
    handleIncreaseItemQty,
    handleDecreaseItemQty,
    addToCart,
    setSelectedVariant,
    handleAddItemToWishlist,
    handleRemoveItem,
    getImageURL,
    handleAddItemToCartFromWishlist,
  };
};

export default useProductHook;
