import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

import ModuleProductImages from "../../../components/elements/products/modules/ModuleProductImages";
import useEcomerce from "../../../hooks/useEcomerce";
import useProduct from "../../../hooks/useProduct";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";

const ProductList = ({ ecomerce, product }) => {
  const { price, badges } = useProduct();
  const navigate = useNavigate();
  const { addItem } = useEcomerce();

  const [quantity, setQuantity] = useState(1);
  const [disable, setDisable] = useState(false);

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  let alreadyinwishlist = product.alreadyinwishlist;

  /*
    To add to wishlist 
  */
  function handleAddItemToWishlist(e) {
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      addItem(
        {
          productid: product.id,
          productvariantid: 0,
        },
        ecomerce.wishlistItems,
        "wishlist"
      );
    } else {
      navigate("/login");
    }
  }

  /*
    To increase item qty 
  */
  const handleIncreaseItemQty = (e) => {
    e.preventDefault();
    let qty = Number(quantity) + 1;
    setQuantity(qty);
    if (Number(product.stock) >= qty) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  /*
    To decrease item qty 
  */
  const handleDecreaseItemQty = (e) => {
    e.preventDefault();
    let qty;
    if (quantity > 1) {
      qty = Number(quantity) - 1;
      setQuantity(qty);
    }
    if (Number(product.stock) >= qty) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  return (
    <div className="ps-product ps-product--horizontal">
      <div className="ps-product__thumbnail">
        <ModuleProductImages product={product} />
        {badges(product)}
      </div>
      <div className="ps-product__wrapper">
        <div className="ps-product__content">
          <h4 className="ps-product__title">
            <Link to={`/product/${product.uniqueid}`} className="producttitle">
              {product.productname}
            </Link>
          </h4>
          <Rating allowFraction initialValue={product.rating} readonly size={15} />
        </div>
        <div className="ps-product__right">
          {price(product)}
          <div className="ps-product__shopping">
            <div className="form-group--number">
              <button className="up" onClick={(e) => handleIncreaseItemQty(e)}></button>
              <button className="down" onClick={(e) => handleDecreaseItemQty(e)}></button>
              <input className="form-control" type="text" value={quantity} placeholder={quantity} />
            </div>
            {disable ? <p className="color-red outstock pt-10 pb-10">Sold Out</p> : ""}

            {alreadyinwishlist === null || alreadyinwishlist === "0" ? (
              <div className="ps-product__quick-actions">
                <a onClick={(e) => handleAddItemToWishlist(e)}>Add to wishlist</a>
              </div>
            ) : (
              <div className="ps-product__quick-actions">
                <a href="/wishlist">View Wishlist</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect((state) => state)(ProductList);
