import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useEcomerce from "../../../../hooks/useEcomerce";
import LocalstorageParam from "../../../../pages/common/LocalstorageParam";

const ModuleDetailShoppingActions = ({ product, ecomerce, productflag, productview }) => {
  const [quantity, setQuantity] = useState(1);
  const { loading, addItem } = useEcomerce();
  const [disable, setDisable] = useState(false);
  const [alreadyinwishlist, setAlreadyinwishlist] = useState(product.alreadyinwishlist);

  const navigate = useNavigate();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To add item to cart
  */
  const handleAddItemToCart = (e) => {
    e.preventDefault();

    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      addItem(
        {
          productid: product.id,
          quantity: quantity,
          productvariantid: product.variantid,
        },
        ecomerce.cartItems,
        "cart"
      );
    } else {
      navigate("/login");
    }
  };

  /*
    To add item to wishlist
  */
  const handleAddItemToWishlist = (e) => {
    e.preventDefault();

    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      addItem(
        {
          productid: product.id,
          productvariantid: 0,
        },
        ecomerce.wishlistItems,
        "wishlist"
      );
      setAlreadyinwishlist(1);
    } else {
      navigate("/login");
    }
  };

  /*
    To increase item qty
  */
  const handleIncreaseItemQty = (e) => {
    e.preventDefault();
    let qty = Number(quantity) + 1;
    setQuantity(qty);
    if (Number(product.stock) >= qty) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  /*
    To decrease item qty
  */
  const handleDecreaseItemQty = (e) => {
    e.preventDefault();
    let qty;
    if (quantity > 1) {
      qty = Number(quantity) - 1;
      setQuantity(qty);
    }
    if (qty >= 1) {
      if (Number(product.stock) >= qty) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  };

  return (
    <div className="ps-product__shopping">
      <div className="ps-product__add-cart">
        <label className="ps-product__label font-size-16 mb-20">Quantity</label>
        <figure>
          <div>
            <div className="form-group--number">
              <button className="up" onClick={(e) => handleIncreaseItemQty(e)}></button>
              <button className="down" onClick={(e) => handleDecreaseItemQty(e)}></button>
              <input className="form-control" type="text" placeholder={quantity} disabled />
            </div>
            {disable ? <p className="color-red outstock pt-10 pb-10">Sold Out</p> : ""}
          </div>
          <div>
            {productview === "preview" ? (
              <a className={"btn ps-btn ps-btn--warning border-radius-50"}>{!loading ? "Add to cart" : "loading"}</a>
            ) : (
              <a
                className={
                  disable || !productflag
                    ? "btn ps-btn ps-btn--warning disabled disable-btn-color border-radius-50"
                    : "btn ps-btn ps-btn--warning border-radius-50"
                }
                onClick={(e) => handleAddItemToCart(e)}
              >
                {!loading ? "Add to cart" : "loading"}
              </a>
            )}
          </div>
        </figure>

        <div className="ps-product__ecomerce-actions">
          {productview === "preview" ? (
            <a href="javascript:;">View Wishlist</a>
          ) : (
            <>
              {alreadyinwishlist === null || Number(alreadyinwishlist) === Number(0) ? (
                <a href="javascript:;" onClick={(e) => handleAddItemToWishlist(e)}>
                  Add to wishlist
                </a>
              ) : (
                <a href="/wishlist">View Wishlist</a>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default connect((state) => state)(ModuleDetailShoppingActions);
