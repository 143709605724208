import { useSearchParams } from "react-router-dom";

/*
  To search parameter for checkout flow
*/
export default function CartParameter() {
  const [searchParams, setSearchParams] = useSearchParams();

  var urlcoupon = searchParams.get("coupon");

  if (!urlcoupon) {
    urlcoupon = "";
  }

  const searchparam = {
    urlcoupon: urlcoupon,
  };

  return searchparam;
}
