import React, { useEffect, useState } from "react";

import faqs from "../../api/faqs";
import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";

const Faqs = () => {
  const [faq, setFaqs] = useState(null);
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "FAQs",
    },
  ];
  useEffect(() => {
    getFaqs();
    window.scrollTo(0, 0);
  }, []);

  /*
    To get faqs using api
  */
  async function getFaqs() {
    const faqlist = await faqs.getFaqs();
    if (faqlist) {
      setFaqs(faqlist.data.payload.data);
    } else {
      return null;
    }
  }

  /*
    To toggle icon using id
  */
  const toggleIcon = (id) => {
    document.getElementById("answer_" + id).classList.toggle("fade");
    document.getElementById("upicon_" + id).classList.toggle("fade");
    document.getElementById("downicon_" + id).classList.toggle("fade");
  };

  /*
    To view
  */
  let faqslist = [];
  if (faq !== null && faq !== "") {
    faq.map((item) => {
      faqslist.push(
        <div className="panel-group mb-10" aria-multiselectable="true">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title mb-0">
                <a role="button" onClick={(e) => toggleIcon(item.id)} aria-expanded="true" className="word-break-all">
                  <i className="more-less fa fa-angle-up openfilter" id={`upicon_${item.id}`}></i>
                  <i className="more-less fa fa-angle-down openfilter fade" id={`downicon_${item.id}`}></i>
                  {item.question}
                </a>
              </h4>
            </div>
            <div
              id={`answer_${item.id}`}
              className="panel-collapse fade openfilter"
              role="tabpanel"
              aria-labelledby="headingOne"
            >
              <div className="panel-body title-font p-15 word-break-all">{item.answer}</div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <Container>
      <GoogleCaptcha />
      <div className="ps-page  form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>

        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">FAQs</h1>
          </div>
          <div className="ps-page__content ps-account">
            <div className="row">
              <div className="col-12 pt-20">
                <p>
                  Welcome to the Store FAQ ! We're here to answer your questions about our ecommerce website and help
                  you have the best shopping experience. Please take a look at our frequently asked questions below.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 pt-20">{faqslist}</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Faqs;
