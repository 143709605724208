import React from "react";

const ModuleProductRating = () => {
  return (
    <div className="ps-product__rating">
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
    </div>
  );
};

export default ModuleProductRating;
