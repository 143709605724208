import { useState } from "react";
import axios from "axios";

import blog from "../api/blog";
import config from "../config/config.json";

export default function useBlog() {
  const [loading, setLoading] = useState(false);
  const [bloglist, setBloglist] = useState([]);
  const [blogdetail, setBlogDetail] = useState([]);
  const [blogcategorylist, setBlogcategorylist] = useState([]);
  const [blogtaglist, setBlogtaglist] = useState([]);
  const [blogComment, setBlogComment] = useState([]);

  const headers = {
    Accept: "*/*",
  };

  return {
    bloglist,
    blogdetail,
    blogcategorylist,
    blogtaglist,
    blogComment,
    setBlogDetail,

    /*
      To get blog list
    */
    getBloglist: async (categoryid, tagid) => {
      setLoading(true);
      try {
        const responseData = await blog.getBloglist(categoryid, tagid);
        if (responseData && responseData.data && responseData.data.status === 200) {
          setBloglist(responseData.data.payload.data);
          setTimeout(() => {
            setLoading(false);
          }, 250);
        } else {
          setLoading(false);
          setBloglist([]);
        }
      } catch (error) {
        console.error("Error fetching blog list:", error);
        setLoading(false);
        setBloglist([]);
      }
    },

    /*
      To get blog detail data by id
    */
    async getBlogDetailById(blogid) {
      setLoading(true);
      try {
        const url = `${config.apiUrl}${config.apiurlname}/blog/${blogid}`;
        const response = await axios.get(url, {
          headers: headers,
        });
        if (response.status === 200) {
          setBlogDetail(response.data.payload.data);
        } else {
          setBlogDetail({});
        }
      } catch (error) {
        setBlogDetail({});
      } finally {
        setLoading(false);
      }
    },

    /*
      To get blog comment list by blog id
    */
    getBlogCommentById: async (blogid, pageno, upperlimit, startindex) => {
      setLoading(true);
      const responseData = await blog.getBlogCommentById(blogid, pageno, upperlimit, startindex);
      if (responseData) {
        setBlogComment(responseData);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      }
    },

    /*
      To get blog category list
    */
    getBlogCategotyList: async () => {
      setLoading(true);

      const responseData = await blog.getBlogCategotyList();

      if (responseData.data.status === 200) {
        setBlogcategorylist(responseData.data.payload.data);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      } else {
        setLoading(false);
        setBlogcategorylist();
      }
    },

    /*
      To get blog tags list
    */
    getBlogTagList: async () => {
      setLoading(true);

      const responseData = await blog.getBlogTagList();

      if (responseData.data.status === 200) {
        setBlogtaglist(responseData.data.payload.data);
        setTimeout(
          function () {
            setLoading(true);
          }.bind(this),
          250
        );
      } else {
        setLoading(false);
        setBlogtaglist();
      }
    },
  };
}