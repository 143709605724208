import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const WhyUs = ({ homedetail, aboutus = false, title, subtitle }) => {
  const [show, setShow] = useState(false);

  /*
    To show video in model
  */
  const showVideo = () => {
    setShow(true);
  };

  /*
    To model close
  */
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {homedetail && Number(homedetail.displaywhyus) === 1 && (
        <>
          <div className="ps-page ps-page--product home-section-div">
            <div className="container">
              <div className="ps-page__content">
                <div className=" ps-reverse">
                  <div className="ps-layout__right">
                    <div className="row mt-80">
                      <div className="col-12 text-left">
                        <h3 className="ps-section__title pb-20 selection-title">About Us</h3>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 pr-25">
                        <p className="color-blue paragraph">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim libero, facilisis eu enim
                          vel, viverra ornare metus. In elementum eros vel odio mattis ultrices. Pellentesque habitant
                          morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut ut tortor sed
                          mauris blandit ullamcorper commodo nec sapien. Pellentesque non viverra diam. Vivamus id odio
                          dolor. Donec imperdiet nisi eget quam facilisis, in tincidunt enim viverra. Sed finibus ante
                          et odio consequat ultricies. Phasellus vel quam malesuada risus viverra ornare. Nulla
                          dignissim, metus sed facilisis ultrices, sapien neque lobortis massa, et finibus felis odio
                          vitae purus.
                        </p>
                        <p className="color-blue paragraph mt-20">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim libero, facilisis eu enim
                          vel, viverra ornare metus. In elementum eros vel odio mattis ultrices. Pellentesque habitant
                          Donec imperdiet nisi eget quam facilisis, in tincidunt enim viverra. Sed finibus ante et odio
                          consequat ultricies. Phasellus vel quam malesuada risus viverra ornare. Nulla dignissim, metus
                          sed facilisis ultrices, sapien neque lobortis massa, et finibus felis odio vitae purus. Donec
                          imperdiet nisi eget quam facilisis, in tincidunt enim viverra. Sed finibus ante et odio
                          consequat ultricies.
                        </p>
                      </div>
                      <div className="col-12 col-md-6 storeimage">
                        <img src="/static/img/home/cover.png" alt="video" className="" />
                        <i className="fa fa-play"></i>
                      </div>
                    </div>
                    {!aboutus && (
                      <div className="row mt-50">
                        <div className="col-12 col-md-12 text-center">
                          <p>
                            <Link className="bordered-btn" to={"/aboutus"}>
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal show={show} onHide={handleClose} className="modal-width popup-model" size={"lg"}>
        <Modal.Body>
          <div className="ps-form--review">
            <div className="row">
              <div className="col-12 youtubevideo"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default WhyUs;
