import React from "react";
import { Link } from "react-router-dom";
import shopconfig from '../../../config/shopconfig.json';

const Logo = ({ url = "/", type = "default" }) => {
  if (type == "white") {
    return (
      <Link to={"/"}>
        <span className="ps-logo">
          <img src={shopconfig.shop_logo} alt="logo" />
        </span>
      </Link>
    );
  } else {
    return (
      <Link to={"/"}>
        <span className="ps-logo">
          <img src={shopconfig.shop_logo} alt="logo" />
        </span>
      </Link>
    );
  }
};

export default Logo;
