import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoDataFound from "../../components/common/NoDataFound";
import ModuleEcomerceWishlist from "../../components/ecomerce/modules/ModuleEcomerceWishlist";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import SkeletonTable from "../../components/elements/skeletons/SkeletonTable";
import Container from "../../components/layouts/Container";
import useEcomerce from "../../hooks/useEcomerce";
import { caculateArrayQuantity } from "../../utilities/ecomerce-helpers";
import LocalstorageParam from "../common/LocalstorageParam";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Shop",
    url: "/shop",
  },
  {
    text: "Wishlist",
  },
];

const WishlistScreen = ({ ecomerce }) => {
  const { loading, wish_list, getWishlist } = useEcomerce();
  const { currencyrate, getcurrencyRate } = useEcomerce();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      getcurrencyRate();
      if (ecomerce) {
        getWishlist(ecomerce.wishlistItems);
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/login");
    }
  }, [ecomerce]);

  return (
    <Container title="Wishlist">
      <GoogleCaptcha />
      <div className="ps-page ps-page--inner form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">
              Wishlist
              {wish_list && wish_list.length > 0 && <sup>({caculateArrayQuantity(wish_list)})</sup>}
            </h1>
          </div>
          <div
            className={
              wish_list && wish_list.length > 0
                ? "ps-page__content wishlist card-box-shadow bn"
                : "ps-page__content wishlist"
            }
          >
            {wish_list && wish_list.length > 0 ? (
              <ModuleEcomerceWishlist product={wish_list} currencyrate={currencyrate} />
            ) : (
              <>{loading ? <SkeletonTable rows={1} /> : <NoDataFound component={"wishlist"} />}</>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(WishlistScreen);
