import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import config from '../../../config/config.json';
import useProductHook from '../../../hooks/useProductHook';
import useProduct from "../../../hooks/useProduct";
import ModuleProductActions from "../../../components/elements/products/modules/ModuleProductActions";
import ModuleProductImages from "../../../components/elements/products/modules/ModuleProductImages";
import ProductVariantModal from "../../shared/modals/ProductVariantModal";

const Product = ({ product, currencyrate, ecomerce }) => {
  const {
    quantity,
    disable,
    isVarientobject,
    show,
    productItem,
    productStock,
    variantArray,
    groupedVariantArray,
    handleClose,
    handleAddItemToCart,
    handleIncreaseItemQty,
    handleDecreaseItemQty,
    addToCart,
    setSelectedVariant
  } = useProductHook(product, currencyrate, ecomerce);
  const { price, badges } = useProduct();

  return (
    <>
      <div className="ps-product ps-product--grid">
        <div className="ps-product__thumbnail p-0">
          <Link to={`/product/${product.uniqueid}`} className="ps-product__overlay"></Link>
          <ModuleProductImages product={product} />
          <ModuleProductActions product={product} />
          {badges(product)}
        </div>
        <div className="ps-product__content products text-left">
          <h4 className="ps-product__title title-font">
            <Link to={`/product/${product.uniqueid}`} className="producttitle">
              {product.productname}
            </Link>
          </h4>
          {price(product, currencyrate)}
        </div>
        <div className="col-md-12 pl-0 pr-0 addto-cart-section">
          {productStock > 0 && (
            <div className="row mb-25 text-center margin-horizontal-10">
              {config.isdispalayquantity === 0 ? (<>
                <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12 mt-10 pl-0 pr-0 text-center">
                  <div className="form-group--number qty-btn">
                    <button className="up" onClick={(e) => handleIncreaseItemQty(e)}></button>
                    <button id="decrease-btn" className="down" onClick={(e) => handleDecreaseItemQty(e)}></button>
                    <input className="form-control" type="text" placeholder={quantity} disabled />
                  </div>
                </div>
                <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 mt-10 text-center">
                  <div>
                    <a className="shop-btn shop-btn-fill-right pro-add-btn" onClick={(e) => handleAddItemToCart(e)}>
                      Add
                    </a>
                  </div>
                </div>
              </>) : (<>
                <div className="col-12 mt-10">
                  <a className="shop-btn shop-btn-fill-right pro-add-btn col-md-12" onClick={(e) => handleAddItemToCart(e)}>
                    Add
                  </a>
                </div>
              </>)}
            </div>
          )}
        </div>
        {(disable || productStock < 1) && (
          <div className="col-md-12 col-lg-12 col-12">
            <p className="color-red outstock pt-10 pb-10 text-center">Sold Out</p>
          </div>
        )}
      </div>
      <ProductVariantModal
        show={show}
        handleClose={handleClose}
        productItem={productItem}
        isVarientobject={isVarientobject}
        currencyrate={currencyrate}
        variantArray={variantArray}
        groupedVariantArray={groupedVariantArray}
        setSelectedVariant={setSelectedVariant}
        product={product}
        addToCart={addToCart}
      />
    </>
  );
};

export default connect((state) => state)(Product);