import React from "react";

import Error404 from "../../components/common/Error404";
import Container from "../../components/layouts/Container";

const Error_404 = () => {
  return (
    <Container>
      <div className="ps-page  form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <Error404 />
        </div>
      </div>
    </Container>
  );
};
export default Error_404;
