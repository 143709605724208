import React from "react";

import NoDataFound from "../../components/common/NoDataFound";
import OrderListItem from "./OrderListItem";

const PastOrderItemList = ({ orderItem }) => {
  /*
    To past order list views
  */

  const filteredItems = orderItem?.filter(
    (item) => item.status === "Completed" || item.status === "Refunded"
  );
  if (!filteredItems || filteredItems.length === 0) {
    return <NoDataFound component={"order"} />;
  }

  return (
    <>
      {filteredItems.map((item) => (
        <OrderListItem key={item.id} item={item} />
      ))}
    </>
  );


}

export default PastOrderItemList;
