import React from "react";
import { Rating } from "react-simple-star-rating";
import Slider from "react-slick";

import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import config from "../../config/config.json";

const CustomerStory = ({ customerreview, homedetail, title, subtitle }) => {
  let carouselitem = [];

  /*
    To customer view
  */
  if (customerreview && customerreview.length > 0) {
    customerreview.map((item) => {
      let imagepath = config.apiUrl + config.image_path;
      if (item.imagename !== null && item.imagename !== "") {
        imagepath = imagepath + item.imagename;
      } else {
        imagepath = "/static/img/static_image/user_image.png";
      }

      carouselitem.push(
        <div className="carousel-item ps-layout--grid bn shop-items-list similar-product">
          <div className="ps-category__item  m-10">
            <div className="ps-layout__item review-card-home card-box-shadow bn" key={item.id}>
              <div className="ps-product ps-product--grid">
                <div className="ps-product__content">
                  <div className="row justify-content-center">
                    <div>
                      <img src={imagepath} alt="alt" className="img-responsive customer-img customer-home-image" />
                    </div>
                    <div className="col-12 text-center">
                      <h3>
                        {item.firstname} {item.lastname}
                      </h3>
                    </div>
                    <div className="col-12 text-center">
                      <Rating allowFraction initialValue={item.rating} readonly size={15} />
                    </div>
                    <div className="col-12 text-center">
                      <span className="title-font review-description">{item.review}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  /*
    To carousel setting
  */

  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    lazyload: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {carouselitem && carouselitem.length > 0 && (
        <div className="ps-page ps-page--product home-section-div">
          <div className="container">
            <div className="ps-page__content">
              <div className=" ps-reverse">
                <div className="ps-layout__right">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h3 className="sectionname"> {subtitle ? subtitle : homedetail.displaycustomerreviewsubtitle}</h3>
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="ps-section__title pb-20 selection-title">
                        {title ? title : homedetail.displaycustomerreviewtitle}
                      </h3>
                    </div>
                    <div className="col-12">
                      <Slider {...carouselSetting} className="ps-carousel home-slider">
                        {carouselitem}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomerStory;
