import axios from "axios";

import config from "../config/config.json";

const headers = {
  Accept: "*/*",
  Authorization: "Bearer " + localStorage.getItem("authToken"),
};

/*
   To get order list
*/
const getOrderlist = async () => {
  const url = config.apiUrl + config.apiurlname + "/user/orders";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
   To get order detail
*/
const getOrderDetail = async (orderid) => {
  const url = config.apiUrl + config.apiurlname + "/user/orders/" + orderid;
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
   To get Order Shippment
*/
const getOrderShippment = async (orderid) => {
  const url = config.apiUrl + config.apiurlname + "/user/orders/shipping/" + orderid;
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
   To coupon validation
*/
const couponValidation = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/coupon/validatecoupon";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
   To get coupon 
*/
const getCoupon = async () => {
  const url = config.apiUrl + config.apiurlname + "/coupon/customercoupon";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    }).catch((error) => {
      if (error.response) {
        return error.response
      }
    });
  return res;
}



/*
   To get order return products
*/
const orderReturnProduct = async (orderid) => {
  var data = new Object();
  data["orderid"] = orderid;

  const url = config.apiUrl + config.apiurlname + "/orderreturn/products";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
   To save order return
*/
const saveOrderReturn = async (data) => {
  headers["content-type"] = "application/json";

  const url = config.apiUrl + config.apiurlname + "/orderreturn/save";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
   To uploade order return images
*/
const uploadOrderReturnImage = async (file) => {
  const data = new FormData();
  data.append("returnimage", file);

  headers["content-type"] = "multipart/form-data";

  const url = config.apiUrl + config.apiurlname + "/fileupload/multiple";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
   To get Order Shippment
*/
const getTrackOrderHistory = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/trackmyorder";
  const res = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

export default {
  getOrderlist,
  getOrderDetail,
  getOrderShippment,
  couponValidation,
  getCoupon,
  orderReturnProduct,
  saveOrderReturn,
  uploadOrderReturnImage,
  getTrackOrderHistory,
};
