import React from "react";
import { Link } from "react-router-dom";

/*
  To get element position
*/
function getPosition(element) {
  if (element !== null && element !== "") {
    var rect = element.getBoundingClientRect();
    return rect;
  }
}

const SubMenuDropdown = ({ source }) => {
  let createul = [];
  /*
    To subcategory menu
  */
  if (source.subcategories) {
    var ulcount = Number(source.subcategories.length) / 6;
    ulcount = Math.floor(Number(ulcount) + 1);

    if (document.getElementById("mega_menu_" + source.id)) {
      document
        .getElementById("mega_wrapper_" + source.id)
        .style.setProperty("grid-template-columns", "repeat(" + ulcount + ", 1fr)");
      if (Number(ulcount) <= 2) {
        document.getElementById("mega_menu_" + source.id).style.setProperty("width", "auto");

        document.getElementById("mega_menu_" + source.id).classList.add("left-unset");
      } else {
        document.getElementById("mega_menu_" + source.id).style.setProperty("width", "unset");

        var element = document.getElementById("menuid_" + source.id);
        var pos = getPosition(element);
        if (pos) {
          document.getElementById("mega_menu_" + source.id).style.setProperty("left", "5%");
          var right = Math.floor(Number(pos.right)) / 2 + 100;
          document.getElementById("mega_menu_" + source.id).style.setProperty("right", right + "px");
        }
      }
    }

    /*
      To view 
    */
    var temp = 0;
    let templi = [];

    for (var i = 0; i < ulcount; i++) {
      for (var a = temp; a < 6 + Number(temp); a++) {
        if (source.subcategories[a]) {
          templi.push(
            <li className="menu-item-has-children dropdown" key={a}>
              <Link to={source.subcategories[a].url}>{source.subcategories[a].categoryname}</Link>
            </li>
          );
        }
      }
      temp = a;
      if (templi && templi.length > 0) {
        createul.push(
          <div className="mega-menu__column" key={i}>
            <ul className="mega-menu__list menu--single" key={i}>
              {templi}
            </ul>
          </div>
        );
        templi = [];
      }
    }
  }

  return <>{createul}</>;
};

export default SubMenuDropdown;
