import React from "react";

const ModuleFooterCopyright = () => {
  return (
    <span className="color-blue" style={{ fontSize: 12, fontWeight: "lighter" }}>
      Copyright &copy; 2024. Developed and Managed By{" "}
      <a href="https://iconicussoft.com/" target="_blank">
        Iconicus Softwares Private Limited
      </a>
    </span>
  );
};

export default ModuleFooterCopyright;
