import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { currencyPrice } from "../../hooks/currencyCalculation";
import UrlParameter from "../common/UrlParameter";
import { UrlProduct } from "./LoadProduct";

const ProductTag = ({ filtergroup, currencyrate, category }) => {
  /*
    To tag add in array
  */
  let filtertags = [];
  let filtername = [];
  let filterid = [];
  let filtergroupid = [];
  let categoryname = "";
  /*
    To set url paramere in json
  */
  var searchParam = UrlParameter();
  let filters = searchParam.filters;
  let searchkey = searchParam.searchkey;
  let minimumprice = searchParam.minimumprice;
  let maximumprice = searchParam.maximumprice;
  let categoryid = searchParam.categorylist;
  let rating = searchParam.rating;

  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterTags, setIsFilterTags] = useState([]);
  const [isFilterName, setIsFilterName] = useState([]);
  const [isFilterGroupID, setIsFilterGroupID] = useState([]);
  const [isFilterID, setIsFilterID] = useState([]);
  const [isCategoryname, setIsCategoryName] = useState("");
  const [currencyratedata, setCurrencyrate] = useState(currencyrate);
  const [filtergrouplist, setFilterGroup] = useState(filtergroup);
  const [categorylist, setCategoryList] = useState(category);

  const navigate = useNavigate();

  useEffect(() => {
    setIsFilterTags([]);
    setCategoryList(category);
    setCurrencyrate(currencyrate);
    setFilterGroup(filtergroup);

    searchFilter();
    rateFilter();
    minMaxFilter();
    categoryFilter();
    filterGroupfilters();
  }, [
    searchParam.parentcategory,
    searchParam.searchkey,
    minimumprice,
    maximumprice,
    rating,
    categoryid,
    category,
    categorylist,
    currencyrate,
    currencyratedata,
    filtergroup,
    filtergrouplist,
  ]);

  /*
    To searchkey keyword set in array
  */
  const searchFilter = () => {
    if (searchkey) {
      const index = filtertags.indexOf(searchkey);
      if (index <= -1) {
        filtertags.push(searchkey);
        filtername.push("Search key: ");
        filtergroupid.push("Search");
        setIsFilterTags(filtertags);
        setIsFilterName(filtername);
        setIsFilterGroupID(filtergroupid);
      }
    }
  };

  /*
    To category name set in array
  */
  const categoryFilter = () => {
    if (categoryid && categoryid !== null && categoryid !== "" && categoryid !== "null") {
      if (category && category.length > 0) {
        let list = category.find((item) => Number(item.id) === Number(categoryid));

        filtertags.push(list.categoryname);
        categoryname = list.categoryname;
        filtername.push("Category: ");
        filtergroupid.push("Category");
        setIsFilterTags(filtertags);
        setIsFilterName(filtername);
        setIsFilterGroupID(filtergroupid);
        setIsCategoryName(categoryname);
      }
    }
  };

  /*
    To min and max value set in array
  */
  const minMaxFilter = () => {
    if (minimumprice) {
      const index = filtertags.indexOf(
        currencyPrice(minimumprice, currencyratedata) + ` - ` + currencyPrice(maximumprice, currencyratedata)
      );
      if (index <= -1) {
        filtertags.push(
          currencyPrice(minimumprice, currencyratedata) + ` - ` + currencyPrice(maximumprice, currencyratedata)
        );
        filtername.push("Price: ");
        filtergroupid.push("Price");
        setIsFilterTags(filtertags);
        setIsFilterName(filtername);
        setIsFilterGroupID(filtergroupid);
      }
    }
  };

  /*
    To filter id and name set in array
  */
  const filterGroupfilters = () => {
    if (filtergroup) {
      if (filters) {
        for (var filter of filtergroup) {
          var filtergroupname = "";
          if (filter.filterlist !== null && filter.filterlist !== "") {
            for (var fid of filters.split(",")) {
              let list = filter.filterlist.find((item) => Number(item.id) === Number(fid));
              if (list) {
                filtergroupname = filtergroupname + list.filtername + ",";
                if (filterid.indexOf(list.id) === -1) {
                  filterid.push(list.id);
                }
                filtergroupid.push(filter.id);
              }
            }
            filtergroupname = filtergroupname.slice(0, -1);
            if (filtergroupname) {
              filtertags.push(filtergroupname);
              filtername.push(filter.filtergroupname + ": ");
              setIsFilterTags(filtertags);
              setIsFilterName(filtername);
            }
          }
        }
        if (filtergroupid) {
          filtergroupid = Array.from(new Set(filtergroupid));
          setIsFilterGroupID(filtergroupid);
        }
        if (filterid) {
          setIsFilterID(filterid);
        }
      }
    }
    if (document.getElementById("tagsinput")) {
      if (isFilterTags && isFilterTags.length > 0) {
        document.getElementById("tagsinput").classList.remove("hide");
      } else {
        document.getElementById("tagsinput").classList.add("hide");
      }
    }
  };

  /*
    To rating id set in array
  */
  const rateFilter = () => {
    if (rating) {
      const index = filtertags.indexOf(rating);
      if (index <= -1) {
        filtertags.push(rating);
        filtername.push("Rating: ");
        filtergroupid.push("Rating");
        setIsFilterTags(filtertags);
        setIsFilterName(filtername);
        setIsFilterGroupID(filtergroupid);
      }
    }
  };

  /*
    To remove tag from array
  */
  const removeTags = (tag, filtergroup_id) => {
    var url = "";

    if (isFilterTags && isFilterTags.length > 0) {
      const index = isFilterTags.indexOf(tag);
      if (index > -1) {
        isFilterTags.splice(index, 1);
        if (searchkey && searchkey !== null && searchkey !== "" && tag === searchkey) {
          searchkey = "";
          url = UrlProduct(searchParams, "search") + "&searchkey=";
        } else if (
          minimumprice &&
          minimumprice !== null &&
          minimumprice !== "" &&
          tag === currencyPrice(minimumprice, currencyratedata) + ` - ` + currencyPrice(maximumprice, currencyratedata)
        ) {
          minimumprice = "";
          maximumprice = "";
          url = UrlProduct(searchParams, "price") + "&minimumprice=&maximumprice=";
        } else if (rating && rating !== null && rating !== "" && tag === rating) {
          rating = "";
          url = UrlProduct(searchParams, "rating") + "&rating=";
        } else if (categoryid && categoryid !== null && categoryid !== "" && tag === isCategoryname) {
          categoryid = "";
          url = UrlProduct(searchParams, "category") + "&category=";
        } else if (isFilterGroupID && isFilterGroupID.length > 0) {
          let list = filtergrouplist.find((item) => Number(item.id) === Number
            (filtergroup_id));
          if (list) {
            for (let filter of list.filterlist) {
              const filterindex = isFilterID.indexOf(filter.id);
              if (filterindex > -1) {
                isFilterID.splice(filterindex, 1);
              }
              if (document.getElementById("filter_" + filter.id)) {
                document.getElementById("filter_" + filter.id).checked = false;
              }
            }
          }
          filterid = "";
          url = UrlProduct(searchParams, "filter") + "&filters=" + isFilterID;
        }
      }
    }
    if (document.getElementById("tagsinput")) {
      if (filtertags.length > 0) {
        document.getElementById("tagsinput").classList.remove("hide");
      } else {
        document.getElementById("tagsinput").classList.add("hide");
      }
    }
    redirectProduct(url);
  };

  /*
    To set redirect url 
  */
  const redirectProduct = (url) => {
    navigate(url);
  };

  return (
    <>
      <div className="tags-input-container hide" id="tagsinput">
        {isFilterTags &&
          isFilterTags.map((tag, index) => (
            <div className="tag-item" key={index}>
              <span className="text">
                <span>{isFilterName[index]}</span>
                <span className="font-size-16 font-weight-100"> {tag}</span>
              </span>
              <span className="remove">
                <a onClick={(e) => removeTags(tag, isFilterGroupID[index])}>
                  <i className="fa fa-close"></i>
                </a>
              </span>
            </div>
          ))}
        <input type="text" readOnly className="tags-input" />
      </div>
    </>
  );
};
export default ProductTag;
