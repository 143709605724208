import React from "react";

import ModuleFooterCopyright from "../../../../components/shared/footers/modules/ModuleFooterCopyright";
import ModuleHeaderSwichers from "../../headers/modules/ModuleHeaderSwitcher";

const ModuleFooterBottom = () => {
  return (
    <div className="ps-footer--bottom footer-bottom">
      <div className="row">
        <div className="col-12 col-md-7 copyright">
          <ModuleFooterCopyright />
        </div>
        <div className="col-12 col-md-5 footer-payment-icon">
          <div className="row">
            <div className="col-4 col-md-4"></div>
            <div className="col-1 text-right paymentdiv">
              <img src="/static/img/payment/visa_logo_7.png" alt="" className="payment-image" />
            </div>

            <div className="col-1 col-md-1 text-right paymentdiv">
              <img src="/static/img/payment/Mastercard-Download-PNG.png" alt="" className="payment-image" />
            </div>
            <div className="col-1 col-md-1 text-right paymentdiv">
              <img src="/static/img/payment/maestro-1-logo-png-transparent.png" alt="" className="payment-image" />
            </div>
            <div className="col-1 col-md-1 text-right paymentdiv">
              <img src="/static/img/payment/289765.png" alt="" className="payment-image" />
            </div>
            <div className="col-4 col-md-4">
              <ModuleHeaderSwichers />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleFooterBottom;
