import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import user from "../../api/user";

const PreFooter = () => {
  const [news_letter, setNewsLatter] = useState("");
  /*
    To set initial values
  */
  const initialValues = {
    subscribe: "",
    newsletter: "",
  };

  /*
    To form validation using yup
  */
  const validationSchema = Yup.object().shape({
    subscribe: Yup.string().email().required("Please enter valid email"),
  });

  /*
   To save news latter
  */
  const saveNewsLetter = async (value) => {
    if (value.subscribe) {
      var data = new Object();
      data["newsletter"] = value.subscribe;
      const res = await user.saveNewsLetter(data);
      if (res.data.status === 200) {
        document.getElementById("subscribefield").value = "";
        document.getElementById("successmsg").classList.remove("hide");
      }
    } else {
      document.getElementById("successmsg").classList.add("hide");
    }
  };
  return (
    <>
      <div className="ps-page__content pre-footer-6">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(value, { resetForm }) => {
            saveNewsLetter(value);
            resetForm("");
          }}
        >
          {(formik) => {
            const { errors, touched } = formik;
            if (errors.subscribe && touched.subscribe) {
              document.getElementById("successmsg").classList.add("hide");
            }
            return (
              <Form>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="pt-10">Get All Recent Updates From Us</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="ps-form__group form-group mb-0">
                            <div className="input-group">
                              <div className="content">
                                <div className="subscription">
                                  <Field
                                    className={
                                      errors.subscribe && touched.subscribe
                                        ? "input-error form-control add-email"
                                        : "form-control add-email"
                                    }
                                    type={"email"}
                                    name={"subscribe"}
                                    placeholder={"Your Email"}
                                    maxLength={"100"}
                                    id="subscribefield"
                                    onKeyUp={(e) => {
                                      document.getElementById("successmsg").classList.add("hide");
                                      e.preventDefault();
                                    }}
                                  />
                                  <button className="submit-email">
                                    <span className="before-submit">Subscribe</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <ErrorMessage name="subscribe" component="span" className="color-red" />
                            <div className="row">
                              <div className="col-12 color-green hide font-size-16 pl-40" id="successmsg">
                                Your subscription has been add.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
export default PreFooter;
