import axios from "axios";

import config from "../config/config.json";

const headers = {
  Accept: "*/*",
};

/*
   To save contact us
*/
const saveContactUs = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/contactus/save";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

export default {
  saveContactUs,
};
