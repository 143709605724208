import { CountUp } from "countup.js";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import scrollMonitor from "scrollmonitor";

import home from "../../api/home";
import BreadCrumb from "../../components/elements/BreadCrumb";
import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Container from "../../components/layouts/Container";
import config from "../../config/config.json";
import CustomerStory from "../home/CustomerStory";
import WhyUs from "../home/WhyUs";

const AboutUs = () => {
  const [homedata, setHomeData] = useState(null);

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "AboutUs",
    },
  ];

  useEffect(() => {
    getHomeData();
    handleStickyHeader();
    window.scrollTo(0, 0);
  }, []);

  /*
    To countdown start when scroll
  */
  function handleStickyHeader() {
    const requireCounter = document.querySelectorAll(".countup");
    requireCounter.forEach((el) => {
      const elementWatcher = scrollMonitor.create(el);
      elementWatcher.fullyEnterViewport(function () {
        let numberOfDecimals = 0;
        let countTo = parseFloat(el.dataset.to);

        if (countTo % 1 > 0) {
          numberOfDecimals = countTo.toString().split(".")[1].length;
        }
        const countUp = new CountUp(el, countTo, {
          decimalPlaces: numberOfDecimals,
        });

        countUp.start();
        el.dataset.counter_done = true;
      });
    });
  }

  /*
    To get home data using api
  */
  const getHomeData = async () => {
    const responseData = await home.getHomeData();
    if (responseData) {
      setHomeData(responseData.data.payload);
    }
  };

  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    lazyload: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  var customerreview = "";
  let carouselitem = [];

  /*
    To carousel view
  */
  if (homedata) {
    customerreview = homedata.customerreview;
    if (customerreview && customerreview.length > 0) {
      customerreview.map((item) => {
        let imagepath = config.apiUrl + config.image_path;
        if (item.imagename !== null && item.imagename !== "") {
          imagepath = imagepath + item.imagename;
        } else {
          imagepath = "/static/img/static_image/user_image.png";
        }

        carouselitem.push(
          <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list similar-product">
            <div className="ps-category__item  m-10">
              <div className="ps-layout__item customer-cart card-box-shadow bn" key={item.id}>
                <div className="ps-product ps-product--grid">
                  <div className="ps-product__content">
                    <div className="row justify-content-center">
                      <div>
                        <img src={imagepath} alt="alt" className="img-responsive customer-img customer-home-image" />
                      </div>
                      <div className="col-12 text-center">
                        <h3>
                          {item.firstname} {item.lastname}
                        </h3>
                      </div>
                      <div className="col-12 text-center">
                        <Rating allowFraction initialValue={item.rating} readonly size={15} />
                      </div>
                      <div className="col-12">
                        <span className="title-font review-description">{item.review}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <Container>
      <GoogleCaptcha />
      <div id="aboutusid">
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
          </div>
        </div>

        <div className="ps-page form-loader">
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
          <div className="home-background-image">
            <div className="ps-page ps-page--product home-section-div">
              <div className="container">
                <div className="ps-page__content">
                  <div className=" ps-reverse">
                    <div className="ps-layout__right">
                      <div className="row">
                        <div className="col-12 pt-20">
                          <div className="row">
                            <div className="col-12 text-left">
                              <h3 className="ps-section__title pb-20 selection-title"> About Us</h3>
                            </div>
                            <div className="col-12">
                              <span className="title-font">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rutrum nulla risus, sed
                                rutrum mauris pellentesque at. Nulla facilisi. Aenean vitae hendrerit velit. Proin quis
                                diam tincidunt tellus vestibulum maximus vitae quis neque. Maecenas facilisis ac justo
                                vel hendrerit. Suspendisse ac nulla condimentum felis vehicula maximus vel ut nisi. Nunc
                                feugiat sapien erat, id efficitur velit tincidunt at. Nulla lobortis, nisi eu elementum
                                pulvinar, quam turpis hendrerit nisi, ut maximus metus quam non turpis. Vestibulum
                                vestibulum placerat purus in gravida. Proin iaculis iaculis pharetra. Praesent faucibus
                                hendrerit arcu, in malesuada enim dapibus ut. Donec ligula ex, pulvinar sit amet
                                volutpat nec, pretium vulputate libero. Praesent dapibus venenatis vulputate. Etiam
                                sagittis interdum magna sodales tincidunt. Maecenas commodo eros eleifend, iaculis dui
                                sit amet, consequat metus. Nullam ex eros, sollicitudin eu lectus vitae, ultrices
                                tristique neque. Vestibulum lorem ipsum, accumsan nec ultrices ut, viverra eget sapien.
                                Pellentesque turpis ligula, fringilla sit amet lacus eu, iaculis sollicitudin tellus.
                                Cras efficitur tellus vel volutpat tempus. Cras a felis rutrum, auctor tellus in, congue
                                tellus. Vestibulum et pharetra nulla, in hendrerit quam. Maecenas eu rutrum arcu. Nulla
                                ligula arcu, imperdiet volutpat enim non, imperdiet luctus risus. Phasellus vulputate
                                risus ut magna rhoncus, id vulputate magna varius. Vivamus turpis nunc, ultrices sed
                                egestas vitae, imperdiet at eros. Proin at eros vel ex pharetra pulvinar. Duis venenatis
                                orci lacus, ac finibus quam rutrum et. Curabitur id sollicitudin ipsum. Etiam ut turpis
                                risus. Sed quis interdum quam. In hac habitasse platea dictumst. Interdum et malesuada
                                fames ac ante ipsum primis in faucibus. Vestibulum nec tellus consequat, vulputate justo
                                ut, feugiat sapien. Etiam viverra eget neque in venenatis. Proin sit amet ligula sed
                                velit convallis fermentum eu eu leo. Nulla facilisi.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default AboutUs;
