import axios from "axios";

import config from "../config/config.json";

const headers = {
  Accept: "*/*",
  Authorization: "Bearer " + localStorage.getItem("authToken"),
};

/*
   To add wishlist
*/
const addWishlist = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/wishlist/add";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
   To remove wishlist
*/
const removeWishlist = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/wishlist/remove";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
   To get wishlist
*/
const getWishlist = async () => {
  const url = config.apiUrl + config.apiurlname + "/wishlist";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

export default {
  addWishlist,
  removeWishlist,
  getWishlist,
};
