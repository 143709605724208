import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoDataFound from "../../components/common/NoDataFound";
import ModuleEcomerceWishlist from "../../components/ecomerce/modules/ModuleEcomerceWishlist";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import SkeletonTable from "../../components/elements/skeletons/SkeletonTable";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "My Account",
    url: "/profile",
  },
  {
    text: "My Wishlist",
  },
];

const MyWishlist = ({ ecomerce }) => {
  const { loading, wish_list, getWishlist } = useEcomerce();
  const { currencyrate, getcurrencyRate } = useEcomerce();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      getcurrencyRate();
      if (ecomerce) {
        getWishlist(ecomerce.wishlistItems);
      }
      window.scrollTo(0, 0);
    } else {
      navigate("/login");
    }
  }, [ecomerce]);

  return (
    <Container title="Wishlist">
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Wishlist"} />
                </div>
                <div className="ps-layout__right">
                  <div className="row">
                    <div className="col-12 col-md-12 ps-form--review">
                      <h2 className="ps-form__title mb-20">My Wishlist</h2>
                      <div className="box-rounded width-100 wishlist">
                        {wish_list && wish_list.length > 0 ? (
                          <ModuleEcomerceWishlist
                            product={wish_list}
                            wishlistevent={"mywishlist"}
                            currencyrate={currencyrate}
                          />
                        ) : (
                          <>{loading ? <SkeletonTable rows={1} /> : <NoDataFound component={"wishlist"} />}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(MyWishlist);
