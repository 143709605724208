import React from "react";
import Select from "react-select";

const SelectCombo = ({
  label,
  name,
  options,
  placeholder,
  setSelectedCountry,
  initialValues,
  value,
  getState,
  setCountryname,
  setStatename,
  ref,
}) => {
  return (
    <Select
      name={name}
      options={options}
      className="form-control ps-form__input combo-select"
      placeholder={placeholder}
      ref={ref}
      onChange={(value) => {
        setSelectedCountry(value);
        if (name === "country") {
          initialValues.country = value.value;
        } else if (name === "state") {
          initialValues.state = value.value;
        } else if (name === "addressphonecountry") {
          initialValues.addressphonecountry = value.value;
        } else if (name === "billingcountry") {
          initialValues.billingcountry = value.value;
        } else if (name === "billingstate") {
          initialValues.billingstate = value.value;
        } else if (name === "billingmobilecountry") {
          initialValues.billingmobilecountry = value.value;
        } else {
          initialValues.phonecountry = value.value;
        }

        if (getState) {
          getState(value.value);
        }
        if (setCountryname) {
          setCountryname(value.label);
        }
        if (setStatename) {
          setStatename(value.label);
        }
      }}
      value={value}
    />
  );
};
export default SelectCombo;
