import jwt_decode from "jwt-decode";

/*
  To set loacl storage value in json
*/
export default function LocalstorageParam() {
  var decoded = "";
  if (localStorage.getItem("authToken")) {
    decoded = jwt_decode(localStorage.getItem("authToken"));
  }

  const localparam = {
    decoded: decoded,
    sameshipping: localStorage.getItem("sameshipping"),
    billingdata: localStorage.getItem("billingdata"),
  };
  return localparam;
}
