import React from "react";

import config from "../../../../config/config.json";
const PopularCategoriesTwo = ({ category, homedetail }) => {
  /*
    To category list view
  */
  let categorylist = [];
  if (category !== null && category !== "") {
    category.map((item, index) => {
      let imagepath = config.apiUrl + config.image_path;
      if (item.imagename !== null && item.imagename !== "") {
        imagepath = imagepath + item.imagename;
      } else {
        imagepath = "/static/img/noproduct-images.png";
      }

      categorylist.push(
        <figure className="effect-goliath" style={{ width: "18%" }}>
          <img src={imagepath} alt="img23" />
          <figcaption>
            <h2>
              <span>{item.categoryname}</span>
            </h2>
            <p className="cat-view-btn">View more</p>
            <a href={"/shop?parentcategory=" + item.id}>View more</a>
          </figcaption>
        </figure>
      );
    });
  }

  return (
    <div className="ps-section--categories home-section-category">
      <div className="container">
        <div className="col-12 text-center">
          <h3 className="sectionname">{homedetail.displayproductcatsubtitle}</h3>
        </div>
        <div className="col-12 text-center">
          <h3 className="ps-section__title pb-20 selection-title">{homedetail.displayproductcattitle}</h3>
        </div>
        <div className="ps-section__content">
          <div className="">
            <div className="category-fig">
              <div className="grid" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                {categorylist}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCategoriesTwo;
