import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import SkeletonTable from "../../../components/elements/skeletons/SkeletonTable";
import BreadCrumb from "../../../components/elements/BreadCrumb";
import Container from "../../../components/layouts/Container";
import GoogleCaptcha from "../../../components/common/GoogleCaptcha";
import useEcomerce from "../../../hooks/useEcomerce";
import LocalstorageParam from "../../common/LocalstorageParam";
import OderDetailProductList from "../../order/OderDetailProductList";
import OrderSummary from "../../order/OrderSummary";
import SkeletonVendor from '../../../components/elements/skeletons/SkeletonVendor';

const OrderSuccess = () => {
  const { loading, orderdetail, getOrderDetail } = useEcomerce();
  const [disable, handelDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const location = useLocation();
  const paymentObject = location.state && 'paymentObject' in location.state ? location.state.paymentObject : null;
  localStorage.setItem("ordervalueid", paymentObject?.value);

  const urlOrderId = new URLSearchParams(location.search).get("orderid");
  localStorage.setItem("orderid", urlOrderId);

  const orderId = localStorage.getItem("orderid");
  if (orderId) {
    localStorage.removeItem("ordervalueid");
  } else {
    localStorage.removeItem("orderid");
  }

  const id = localStorage.getItem("ordervalueid") || localStorage.getItem("orderid");

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();


  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shop",
      url: "/shop",
    },
    {
      text: "Order Success",
    },
  ];

  useEffect(() => {
    handelDisable(false);
    const fetchOrderDetails = async () => {
      try {
        if (
          localparam !== "" &&
          localparam.decoded !== "" &&
          localparam.decoded.username !== null &&
          localparam.decoded.username !== ""
        ) {
          const detailID = paymentObject ? paymentObject.value : (orderId || id);
          if (detailID) {
            await getOrderDetail(detailID);
            setIsLoading(false);
            window.scroll(0, 0);
          } else {
            console.error("Invalid or missing order ID");
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };
    fetchOrderDetails();
  }, [paymentObject, orderId, id]);

  const updateUrlWithoutNavigation = (url) => {
    window.history.pushState({}, '', url);
  };

  if (orderId) {
    updateUrlWithoutNavigation('/ordersuccess');
  }

  if (isLoading) {
    return (
      <Container title="Loading...">
        <SkeletonVendor />
      </Container>
    );
  }

  if (!orderdetail || orderdetail.length === 0) {
    return (
      <Container title="Invalid Order">
        <div className="ps-page ps-page--inner form-loader">
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="text-center">
              <h1 className="text-danger">Invalid Order</h1>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  /*
    To order product list views
  */
  let orderitemlist = "";
  if (loading) {
    orderitemlist = <SkeletonTable rows={2} />;
  }
  if (orderdetail && orderdetail.length > 0) {
    orderitemlist = (
      <table className="table ps-table ps-table--shopping-cart ps-table--responsive bn" id="orderitem">
        <thead>
          <tr>
            <th>Product</th>
            <th className="text-center ">Quantity</th>
            <th className="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <OderDetailProductList
            orderItem={orderdetail[0].orderproductlist}
            orderdetail={orderdetail}
            renderOrderTrack={true}
          />
        </tbody>
      </table>
    );
  }

  return (
    <Container title="Order Success">
      <GoogleCaptcha />
      <div className="ps-page ps-page--inner form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
          </div>

          {paymentObject || orderId || id ? (
            <>
              <div className="ps-page__content ordersucess">
                <div className="col-12 box-rounded border shadow-none ordersuccess-box">
                  <div className="row pl-10 pr-10 mb-0 mt-0">
                    <div className="col-12 col-md-12">
                      <div className="ps-cart__content">
                        <div className="ps-cart__items order-summary bn">
                          <div className="row text-center">
                            <div className="col-12 mb-20 mt-20">
                              <span>
                                <img src="/static/img/ordersuccess/seccesslogo4-modified.png" className="orderseccess-image img-fluid" />
                              </span>
                              <h2 className=" mt-2 text-success">Thank you for your Order!</h2>
                              <p>The order confirmation email with details of your oder has been sent to your email address </p>
                              <div className="col-12 order-pading mt-1">
                                <strong className="badge badge-success badge-pill order-title-font">Your Order number : {" "}
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].orderno
                                    ? orderdetail[0].orderno
                                    : "-"}
                                </strong>
                              </div>
                              <div className="col-12 order-pading">
                                <p className="order-title-font">Order Date : {" "}
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].orderdate
                                    ? orderdetail[0].orderdate
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row pl-10 pr-10 mb-0 mt-0">
                    <div className="col-12 col-md-12">
                      <div className="ps-cart__content">
                        <div className="ps-cart__items order-summary">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="ps-cart__content">
                                <div className="ps-cart__items">
                                  {orderitemlist && orderitemlist}
                                  <hr />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pl-10 pr-10 mb-20 mt-5">
                    <div className="col-md-6 ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ps-cart__content">
                            <div className="ps-cart__items order-summary bn">
                              <div className="row">
                                <div className="col-12 col-md-12 mb-30">
                                  <h3 className="ps-form__title">Payment Detail</h3>
                                </div>
                              </div>
                              {paymentObject ?
                                (<>
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Payment Status</span>
                                    </div>
                                    <div className="col-6 text-right order-pading">
                                      <span className="ps-text--order title-font">
                                        {paymentObject.paymentstatus}
                                      </span>
                                    </div>
                                  </div>
                                </>) : (<>
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Payment Status</span>
                                    </div>
                                    <div className="col-6 text-right order-pading">
                                      <span className="ps-text--order title-font">
                                        {orderdetail &&
                                          orderdetail.length > 0 &&
                                          orderdetail[0].paymentstatus &&
                                          orderdetail[0].paymentstatus}
                                      </span>
                                    </div>
                                  </div>
                                </>)
                              }
                              {paymentObject ?
                                (<>
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Payment Mode</span>
                                    </div>
                                    <div className="col-6 text-right order-pading">
                                      <span className="ps-text--order title-font">
                                        {paymentObject.paymentmode}
                                      </span>
                                    </div>
                                  </div>
                                </>) : (<>
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Payment Mode</span>
                                    </div>
                                    <div className="col-6 text-right order-pading">
                                      <span className="ps-text--order title-font">
                                        {orderdetail &&
                                          orderdetail.length > 0 &&
                                          orderdetail[0].paymentmode &&
                                          orderdetail[0].paymentmode}
                                      </span>
                                    </div>
                                  </div>
                                </>)
                              }
                              {paymentObject ?
                                (<>
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Tansaction ID</span>
                                    </div>
                                    <div className="col-6 text-right text-break order-pading">
                                      <span className="ps-text--order title-font">
                                        {paymentObject.transactionid}
                                      </span>
                                    </div>
                                  </div>
                                </>) : (<>
                                  <div className="row">
                                    <div className="col-6 order-pading">
                                      <span className="ps-text--order">Transaction ID</span>
                                    </div>
                                    <div className="col-6 text-right text-break order-pading">
                                      <span className="ps-text--order title-font">
                                        {orderdetail &&
                                          orderdetail.length > 0 &&
                                          orderdetail[0].transactionid &&
                                          orderdetail[0].transactionid}
                                      </span>
                                    </div>
                                  </div>
                                </>)
                              }
                              <div className="row">
                                <div className="col-6 order-pading">
                                  <span className="ps-text--order">Status</span>
                                </div>
                                <div className="col-6 text-right text-break order-pading">
                                  <strong className="badge badge-success order-title-font"> {orderdetail &&
                                    orderdetail.length > 0 &&
                                    orderdetail[0].status &&
                                    orderdetail[0].status}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 gap-2 border-left">
                      <div className="ps-cart__content">
                        <OrderSummary orderdetail={orderdetail} renderOrder={true} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row pl-10 pr-10 mb-20 mt-20">
                    <div className="col-md-6">
                      <div className="ps-cart__content">
                        <div className="ps-cart__items order-summary bn">
                          <div className="row">
                            <div className="col-12  mb-30">
                              <h3 className="ps-form__title">Shipping Address</h3>
                            </div>
                            <div className="col-12 order-pading">
                              <span className="ps-text--order">
                                <i className="fa fa-user color-blue"></i>
                                <span className="pl-10 word-break-all color-blue">
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingname}
                                </span>
                              </span>
                            </div>
                            <div className="col-12 order-pading">
                              <span className="ps-text--order">
                                <i className="fa fa-phone color-blue"></i>
                                <span className="pl-10 title-font">
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingmobilecountry}
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingmobile}
                                </span>
                              </span>
                            </div>
                            <div className="col-12 order-pading d-flex">
                              <span className="ps-text--order">
                                <i className="fa fa-map-marker color-blue"></i>
                              </span>
                              <span className="pl-10 title-font word-break-all">
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingaddress} {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingcity} , {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingstatename} {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingcountryname}{" "}
                                {" - "}
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingpincode}
                              </span>
                            </div>
                            <div className="col-12 order-pading">
                              <span className="ps-text--order">
                                <i className="fa fa-building color-blue"></i>
                                <span className="pl-10 title-font word-break-all">
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].shippingcompanyname}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 border-left">
                      <div className="ps-cart__content">
                        <div className="ps-cart__items order-summary bn">
                          <div className="row">
                            <div className="col-12  mb-30">
                              <h3 className="ps-form__title">Billing Address</h3>
                            </div>

                            <div className="col-12 order-pading">
                              <span className="ps-text--order">
                                <i className="fa fa-user color-blue"></i>
                                <span className="pl-10 word-break-all color-blue">
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].billingname}
                                </span>
                              </span>
                            </div>
                            <div className="col-12 order-pading">
                              <span className="ps-text--order">
                                <i className="fa fa-phone color-blue"></i>
                                <span className="pl-10 title-font">
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].billingmobilecountry}
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].billingmobile}
                                </span>
                              </span>
                            </div>
                            <div className="col-12 order-pading d-flex">
                              <span className="ps-text--order">
                                <i className="fa fa-map-marker color-blue"></i>
                              </span>
                              <span className="pl-10 title-font word-break-all">
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].billingaddress} {orderdetail && orderdetail.length > 0 && orderdetail[0].billingcity} , {orderdetail && orderdetail.length > 0 && orderdetail[0].billingstatename} {orderdetail && orderdetail.length > 0 && orderdetail[0].billingcountryname} {" - "}
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].billingpincode}
                              </span>
                            </div>
                            <div className="col-12 order-pading">
                              <span className="ps-text--order">
                                <i className="fa fa-building color-blue"></i>
                                <span className="pl-10 title-font word-break-all">
                                  {orderdetail && orderdetail.length > 0 && orderdetail[0].billingcompanyname}
                                </span>
                              </span>
                            </div>
                            <div className="col-12 order-pading">
                              <span className="ps-text--order color-blue">GST {" :- "}</span>
                              <span className="ps-text--order title-font">
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].billinggst}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center">
              <h1 className="text-danger">Invalid Order</h1>
            </div>
          )}

        </div>
      </div>
    </Container>
  );
};

export default connect((state) => state)(OrderSuccess);
