import { Button, Drawer, Slider } from 'antd';
import React, { useEffect } from 'react';

import useFilter from '../../../hooks/useFilter';

const FilterDrawer = ({ filtergroup, category }) => {
  const {
    searchParams,
    isMenu,
    showFilters,
    dropdowns,
    closeFilters,
    toggleDropdown,
    ratingData,
    handleCategoryClick,
    priceRange,
    handlePriceChange,
    clearAllFilters,
    checkedFilters,
    setCheckedFilters,
    selectedRatings,
    handleRatingChange,
    handleCheckboxChange,
    applyFilter,
  } = useFilter();

  useEffect(() => {
    const urlFilters = searchParams.get('filters') ? searchParams.get('filters').split(',') : [];

    const initialCheckedFilters = (filtergroup || []).reduce((acc, group) => {
      const filterName = group.displayname;
      const subFilters = group.filterlist.reduce((subAcc, filter) => {
        subAcc[filter.id] = urlFilters.includes(filter.id.toString());
        return subAcc;
      }, {});
      acc[filterName] = subFilters;
      return acc;
    }, {});

    setCheckedFilters(initialCheckedFilters);
  }, [filtergroup, searchParams]);

  const filterData = filtergroup && Array.isArray(filtergroup) ? filtergroup.map(group => ({
    name: group.displayname,
    subFilters: group.filterlist.map(filter => ({
      id: filter.id,
      label: filter.filtername,
      count: null
    }))
  })) : [];

  return (
    <>
      <div className='shop-filter'>
        <Button className='filter-button' onClick={showFilters}>
          <span>Filter</span>
          <i class="fa fa-filter"></i>
        </Button>
      </div>
      {isMenu ? (
        <Drawer
          className="ps-panel--mobile mobile-home"
          closable={false}
          placement="right"
          onClose={closeFilters}
          visible={isMenu}
          width={450}
        >
          <div className='filter-drawer'>
            <div className='filter-header'>
              <div className='filter-drawer_close'>
                <i className='icon-cross' onClick={closeFilters}></i>
              </div>
              <div className='filter-drawer_title'>
                <h2>Filter By</h2>
                <p onClick={clearAllFilters}>Clear All</p>
              </div>
            </div>
            <div className='filter-drawer_filter-container'>
              {/* Category Filter */}
              <div>
                <div className='filter-drawer_filter-name' onClick={() => toggleDropdown("Category")}>
                  <span className='filter-name'>Category</span>
                  <span><i className={`icon-chevron-${dropdowns["Category"] ? 'up' : 'down'}`}></i></span>
                </div>
                {dropdowns["Category"] && (
                  <div className='dropdown-filter-container'>
                    <div className="category-filter-container">
                      {category.map((cat) => (
                        <div
                          className="category-item"
                          key={cat.id}
                          onClick={() => handleCategoryClick(cat)}
                        >
                          <input
                            type="checkbox"
                            name="category"
                            className='mr-20'
                            checked={searchParams.get('category') === cat.id.toString()}
                            onChange={() => handleCategoryClick(cat)}
                            onClick={(e) => e.stopPropagation()}
                          />
                          <div>{cat.categoryname}</div>
                          <span className={`${cat.productcount.length > 0 ? "count" : "remove-count"}`}>{cat.productcount}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {/* End of Category Filter */}
              {filterData.map((filter) => (
                <div key={filter.name}>
                  <div className='filter-drawer_filter-name' onClick={() => toggleDropdown(filter.name)}>
                    <span className='filter-name'>{filter.name}</span>
                    <span><i className={`icon-chevron-${dropdowns[filter.name] ? 'up' : 'down'}`}></i></span>
                  </div>
                  {dropdowns[filter.name] && (
                    <div className={`dropdown-filter-container ${filter.name === "Size" ? "size-filter-container" : ""}`}>
                      {filter.name === "Size" ? (
                        <div className='size-options'>
                          <div className='size-grid'>
                            {filter.subFilters.map((subFilter) => (
                              <div
                                className={`size-item ${checkedFilters[filter.name]?.[subFilter.id] ? "selected-size" : ""}`}
                                onClick={() => handleCheckboxChange(filter.name, subFilter.id)}>
                                <input
                                  type="checkbox"
                                  className="btn-check"
                                  checked={checkedFilters[filter.name]?.[subFilter.id] || false}
                                  onChange={() => handleCheckboxChange(filter.name, subFilter.id)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <label className="btn btn-size" for="btn-check-4" onClick={() => handleCheckboxChange(filter.name, subFilter.id)}>{subFilter.label}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        filter.subFilters.map((subFilter) => (
                          <div className='filter-content-container w-100' key={subFilter.id}>
                            <div><input
                              type='checkbox'
                              checked={checkedFilters[filter.name]?.[subFilter.id] || false}
                              onChange={() => handleCheckboxChange(filter.name, subFilter.id)}
                            /></div>
                            <div
                              className='name-count-container w-100'
                              onClick={() => handleCheckboxChange(filter.name, subFilter.id)}
                            >
                              <div>{subFilter.label}</div>
                              <span className={`${subFilter.count ? "count" : "remove-count"}`}>{subFilter.count}</span>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              ))}
              {/* Price Filter */}
              <div>
                <div className='filter-drawer_filter-name' onClick={() => toggleDropdown("Price")}>
                  <span className='filter-name'>Price</span>
                  <span><i className={`icon-chevron-${dropdowns["Price"] ? 'up' : 'down'}`}></i></span>
                </div>
                {dropdowns["Price"] && (
                  <div className="price-filter-container">
                    <Slider
                      range
                      min={0}
                      max={10000}
                      value={priceRange}
                      onChange={handlePriceChange}
                    />
                    <div className="price-inputs">
                      <input
                        type="text"
                        value={priceRange[0]}
                        readOnly
                      />
                      <span> to </span>
                      <input
                        type="text"
                        value={priceRange[1]}
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* End of Price Filter */}
              {/* Rating Filter */}
              <div>
                <div className='filter-drawer_filter-name' onClick={() => toggleDropdown("Rating")}>
                  <span className='filter-name'>Rating</span>
                  <span><i className={`icon-chevron-${dropdowns["Rating"] ? 'up' : 'down'}`}></i></span>
                </div>
                {dropdowns["Rating"] && (
                  <div className="rating-filter-container">
                    {ratingData.map((ratingItem, index) => (
                      <div
                        className="rating-item"
                        key={index}
                        onClick={() => handleRatingChange(ratingItem.rating)}
                      >
                        <input
                          type="checkbox"
                          name="rating"
                          className='mr-20'
                          checked={selectedRatings.includes(ratingItem.rating)}
                          onChange={() => handleRatingChange(ratingItem.rating)}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {[...Array(ratingItem.rating)].map((_, index) => (
                          <i key={index} className="fa fa-star" style={{ color: '#FF9600' }}></i>
                        ))}
                        {[...Array(5 - ratingItem.rating)].map((_, index) => (
                          <i key={index} className="fa fa-star" style={{ color: '#dcdcdc' }}></i>
                        ))}
                        <div>
                          <span className='count'>({ratingItem.productcount})</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* End of Rating Filter */}
            </div>
            <div className='filter-footer'>
              <button className='' onClick={applyFilter}>Apply Filter</button>
            </div>
          </div>
        </Drawer>
      ) : (
        ""
      )}
    </>
  )
}

export default FilterDrawer