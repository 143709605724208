import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Form } from 'formik';

import blog from '../../../api/blog';
import FormikFrom from '../../../components/elements/form/FormikFrom';
import InputField from '../../../components/elements/form/InputField';
import LocalstorageParam from '../../common/LocalstorageParam';
import useBlog from '../../../hooks/useBlog';
import * as Yup from "yup";

const ModuleBlogComment = ({ bloglist, bloginfo }) => {

  const { blogComment, getBlogCommentById } = useBlog();
  const [disable, setDisable] = useState(false);
  const [blogid, setBlogid] = useState(bloglist);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  /*
    To set form initial values
  */
  const initialValues = {
    name: "",
    email: "",
    comment: "",
    blogid: ""
  };

  /*
  To validation address form using yup validation
*/
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter value for Fullname")
      .matches(/^[a-zA-Z '.-]*$/, "Please enter valid Fullname"),
    email: Yup.string().nullable().email().required("Please enter value for Email"),
    comment: Yup.string().required("Please add your comment"),
  });

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const [pageNo, setPageNo] = useState(1);
  const [upperLimit, setUpperLimit] = useState(100);
  const [startIndex, setStartIndex] = useState(0);

  const commentLength = comment.length;
  const CommentUpperLimit = commentLength > 0 ? commentLength : 100;

  useEffect(() => {
    setUpperLimit(CommentUpperLimit);
    getBlogCommentById(bloglist, pageNo.toString(), CommentUpperLimit.toString(), startIndex.toString());
  }, [bloglist, pageNo, CommentUpperLimit, startIndex, comment]);

  let commentsData = blogComment.data;
  /*
    To save comment
  */
  const saveComment = async (data) => {
    document.getElementById("successmsg").classList.add("hide");
    data.blogid = bloglist;
    setDisable(true);
    const res = await blog.saveBlogComment(data);
    if (res.data.status === 200) {
      document.getElementById("successmsg").classList.remove("hide");
      getBlogCommentById(bloglist, pageNo.toString(), CommentUpperLimit.toString(), startIndex.toString());
      setComment("");
      setTimeout(() => {
        setDisable(false);
      }, 500);
    }
  }

  const hideSuccessMessage = () => {
    document.getElementById("successmsg").classList.add("hide");
  }

  return (
    <div className='blog-comment'>
      {commentsData && commentsData.length > 0 ? (
        <>
          <div className='title-text mb-30'>
            <h3>Comments({commentsData.length})</h3>
          </div>
          <div className='comments-list'>
            {commentsData.map((item) =>
              <div className=" card-box-shadow mb-20">
                <div className="blog-comment--blog blogcomment">
                  <div className="row">
                    <div className="col-2 d-flex justify-content-center align-items-center p-0">
                      <div className="blog-comment-image">
                        <img
                          src="/static/img/static_image/user_image.png"
                          alt="alt"
                          className="img-responsive review-user-img"
                        />
                      </div>
                    </div>
                    <div className="col-10 d-flex align-items-center p-0">
                      <div className='blog-user-comment'>
                        <h4 className='mb-0 text-capitalize'>{item.name}</h4>
                        <p className='mb-10 p text-muted'>{item.emailid} | Commented on {item.commentedon}</p>
                        <p className='para-comment'>{item.comment}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (<></>)
      }
      <div className='blog-comment-form'>
        {bloginfo && bloginfo.map((item) => <>
          {item.allowcomment === 1 ? (
            <>
              <div className='title-text mt-40 mb-40'>
                <h3>Add Comment</h3>
              </div>
              <div className="row">
                <div className="col-12 color-green hide font-size-14 mb-10" id="successmsg">
                  Your comment has been added successfully!
                </div>
              </div>
              <div className="ps-form--review pt-0 conatctus-form-padding">
                <FormikFrom
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) => {
                    saveComment(values);
                    resetForm();
                  }}
                >
                  <Form className=" card-box-shadow bn width-100 p-30">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <InputField
                          label={"Full Name *"}
                          name={"name"}
                          type={"text"}
                          placeholder={"Full Name"}
                          maxLength={"50"}
                          id={"name"}
                          value={name}
                          defaultValue={initialValues.name}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <InputField
                          label={"Email *"}
                          name={"email"}
                          type={"email"}
                          placeholder={"Email"}
                          maxLength={"50"}
                          id={"email"}
                          value={email}
                          defaultValue={initialValues.email}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <InputField
                          label={"Comment *"}
                          name={"comment"}
                          placeholder={"Your Comment"}
                          maxLength={"500"}
                          component="textarea"
                          classname="ps-form__textarea"
                          value={comment}
                          rows="3"
                          defaultValue={initialValues.name}
                        />
                      </div>
                    </div>
                    <span className="color-red hide" id="errormsg"></span>
                    <div className="ps-form__submit mt-10">
                      <button
                        className="ps-btn ps-btn--warning width-200"
                        onClick={hideSuccessMessage}
                      >
                        Add Comment
                      </button>
                    </div>
                  </Form>
                </FormikFrom>
              </div>
            </>
          ) : (<></>)} </>
        )}
      </div>
    </div>
  )
}

export default connect((state) => state)(ModuleBlogComment)