import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

import UrlParameter from "../common/UrlParameter";
import { UrlProduct } from "./LoadProduct";

const RatingList = ({ item }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  /*
    To set url paramere in json
  */
  var searchParam = UrlParameter();

  let rating = searchParam.rating;
  useEffect(() => {
    checkedUncheckRate();
  }, [searchParams.get("rating")]);

  let ratingdata = [];
  /*
    To rating list set in array and redirct to shop page
  */
  const ratinglist = (rate, ojb) => {
    checkedUncheckRate();
    if (ojb) {
      ratingdata.push(rate);
    } else {
      const index = ratingdata.indexOf(rate);
      if (index > -1) {
        ratingdata.splice(index, 1);
      }
    }
    navigate(UrlProduct(searchParams, "rating") + "&rating=" + ratingdata);
  };

  /*
    To checked and unchecked rate checkbox
  */
  const checkedUncheckRate = () => {
    if (item) {
      if (document.getElementById("rating_" + item.rating)) {
        document.getElementById("rating_" + item.rating).checked = false;
      }
    }
    if (rating && rating !== null && rating !== "") {
      for (var rid of rating.split(",")) {
        ratingdata.push(Number(rid));

        if (document.getElementById("rating_" + rid)) {
          document.getElementById("rating_" + rid).checked = true;
        }
      }
    }

    if (ratingdata) {
      ratingdata = Array.from(new Set(ratingdata));
    }
  };

  return (
    <div className="widget__content">
      {item ? (
        <div className="form-group">
          <div className="ps-checkbox">
            <input
              className="form-control"
              type="checkbox"
              id={"rating_" + item.rating}
              value={item ? item.rating : "0"}
              name={item.rating}
              onChange={(e) => ratinglist(item.rating, e.target.checked)}
            />
            <label htmlFor={"rating_" + item.rating}>
              <span className="ps-rating">
                <Rating allowFraction initialValue={item.rating} readonly size={20} />
              </span>
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default RatingList;
