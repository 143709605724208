import { useSearchParams } from "react-router-dom";

/*
  To search parameter 
*/
export default function UrlParameter() {
  const [searchParams, setSearchParams] = useSearchParams();

  let layout = searchParams.get("layout");
  let columns = searchParams.get("columns");
  let parentcategory = searchParams.get("parentcategory");
  let filters = searchParams.get("filters");
  let searchkey = searchParams.get("searchkey");
  let minimumprice = searchParams.get("minimumprice");
  let maximumprice = searchParams.get("maximumprice");
  let categorylist = searchParams.get("category");
  let rating = searchParams.get("rating");
  if (!columns) {
    columns = "4";
  }
  if (!layout) {
    layout = "grid";
  }

  const searchparam = {
    columns: columns,
    layout: layout,
    parentcategory: parentcategory,
    filters: filters,
    searchkey: searchkey,
    minimumprice: minimumprice,
    maximumprice: maximumprice,
    categorylist: categorylist,
    rating: rating,
  };

  return searchparam;
}
