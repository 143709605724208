import React, { useState } from "react";

import Logo from "../../../components/elements/basic/Logo";
import FormSearchHeader from "../../../components/shared/forms/FormSearchHeader";
import ModuleHeaderNotice from "../../../components/shared/headers/modules/ModuleHeaderNotice";

const HeaderMobile = ({ note }) => {
  const [search, setSearch] = useState(false);

  /*
    To handle toggle search
  */
  const handleToggleSearch = (e) => {
    e.preventDefault();
    setSearch(!search);
  };

  return (
    <>
      <ModuleHeaderNotice note={note && note.homedetail ? note.homedetail.topbartitle : ""} classes="mobile-only" />
      <header className="header header--mobile" data-sticky="true" id="header-sticky-mobile">
        <div className="header__left">
          <Logo />
        </div>
        <div className="header__right">
          <a className="header__search" href="#" onClick={(e) => handleToggleSearch(e)}>
            <i className="icon-magnifier"></i>
          </a>
        </div>
        {search && (
          <div className="header__searchbox">
            <FormSearchHeader />
          </div>
        )}
      </header>
    </>
  );
};

export default HeaderMobile;
