import React from 'react';
import { connect } from 'react-redux';

import ModuleBlog from './ModuleBlog'
import ModuleBlogDescription from './ModuleBlogDescription'
import SkeletonVendor from '../../../components/elements/skeletons/SkeletonVendor';

const ModuleBlogDetail = ({ blogdetail }) => {

  return (
    <div className='blog-detail'>
      {blogdetail && blogdetail !== null && blogdetail !== "" && blogdetail.length > 0 ?
        <div className='row p-0'>
          <div className='col-md-12 p-0'>
            <ModuleBlog bloglist={blogdetail} isDetailPage={true} />
            <div className='mt-0'>
              <ModuleBlogDescription bloglist={blogdetail} />
            </div>
          </div>
        </div>
        : <SkeletonVendor />}
    </div>
  )
}

export default connect((state) => state)(ModuleBlogDetail)