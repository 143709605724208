import React, { useEffect } from 'react';
import { connect } from "react-redux";
import ModuleBlog from './ModuleBlog';
import useBlog from '../../../hooks/useBlog';
import NoDataFound from '../../../components/common/NoDataFound';

const ModuleBlogList = ({ categoryId, tagId }) => {

  const { loading, bloglist, getBloglist } = useBlog();

  useEffect(() => {
    if (categoryId && tagId) {
      getBloglist(categoryId, tagId);
    } else if (categoryId) {
      getBloglist(categoryId);
    } else if (tagId) {
      getBloglist("", tagId);
    } else {
      getBloglist();
    }
    window.scrollTo(0, 0);
  }, [categoryId, tagId]);

  return (
    <div className=''>
      {bloglist && bloglist !== null && bloglist !== "" && bloglist.length > 0 ?
        <div className="row row-cols-1 row-cols-md-2 g-4 mr-10">
          <ModuleBlog bloglist={bloglist} />
        </div>
        : <NoDataFound component={"blog"} />}
    </div>
  )
}

export default connect((state) => state)(ModuleBlogList); 