import React from "react";
import MetaTags from "react-meta-tags";

import FooterDefault from "../../components/shared/footers/FooterDefault";
import HeaderDefault from "../../components/shared/headers/HeaderDefault";
import HeaderMobile from "../../components/shared/mobiles/HeaderMobile";
import PreFooter from "../common/PreFooter";
import shopconfig from "../../config/shopconfig.json";

const Container = ({
  children,
  title = shopconfig.shop_title,
  footer = <FooterDefault />,
  metatitle,
  metadesc,
  metakeyword,
  note,
}) => {
  /*
    To view
  */
  let titleView;
  if (title !== undefined) {
    titleView = process.env.title + " | " + title;
  } else {
    titleView = process.env.title + " | " + process.env.titleDescription;
  }

  return (
    <div className="ps-root" id="commonpagediv">
      <MetaTags>
        <title>{metatitle ? metatitle : title}</title>
        {metatitle && <meta name="title" content={`${metatitle}`}></meta>}
        {metadesc && <meta name="description" content={`${metadesc}`}></meta>}
        {metakeyword && <meta name="keyword" content={`${metakeyword}`}></meta>}
      </MetaTags>
      <HeaderDefault note={note} />
      <HeaderMobile note={note} />
      {children}
      {/* <PreFooter /> */}
      {footer}
    </div>
  );
};

export default Container;
