import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";

const SelectText = ({ name, label, list }) => {
  const { errors, touched } = useFormikContext();
  return (
    <div className="ps-form__group form-group">
      <label className="ps-form__label">{label}</label>
      <div className="input-group">
        <Field name={name} className="form-control ps-form__input" as="select">
          <option value={""}>Select {label}</option>
          {list}
        </Field>
      </div>
      <ErrorMessage name={name} component="span" className="color-red" />
    </div>
  );
};
export default SelectText;
