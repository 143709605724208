import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import User from "../../api/user";
import config from "../../config/config.json";

const GoogleCaptcha = () => {
  /*
   To verify google recaptcha using key
  */
  const recaptchaRef = React.useRef();
  const key = config.google_capcha_varification_secret;
  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();
    const res = User.verifyGoogleCapcha(token);
  };

  return (
    <form onSubmit={onSubmitWithReCAPTCHA}>
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={key} />
    </form>
  );
};
export default GoogleCaptcha;
