import React, { useEffect } from "react";
import { connect } from "react-redux";

import Logo from "../../../components/elements/basic/Logo";
import FormSearchHeader from "../../../components/shared/forms/FormSearchHeader";
import ModuleHeaderActions from "../../../components/shared/headers/modules/ModuleHeaderActions";
import ModuleHeaderNotice from "../../../components/shared/headers/modules/ModuleHeaderNotice";
import NavigationPrimary from "../../../components/shared/navigations/NavigationPrimary";
import useEcomerce from "../../../hooks/useEcomerce";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";
import { throttle } from "lodash";

const HeaderDefault = ({ ecomerce, classes = "", note }) => {
  const { ckeckLoginToken } = useEcomerce();

  /*
    To add and remove class
  */
  const handleShownav = (e) => {
    e.preventDefault();
    const header_bottom_div = document.getElementById("header_bottom_div");
    if (header_bottom_div.classList.contains("active")) {
      header_bottom_div.classList.remove("active");
    } else {
      header_bottom_div.classList.add("active");
    }
  };

  /*
    To add and remove class for desk view
  */
  const handleStickyHeader = () => {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const header = document.getElementById("header-sticky");
    const header_bottom_div = document.getElementById("header_bottom_div");

    if (header !== null) {
      if (number > 0) {
        header.classList.add("header--sticky");
      } else if (number >= 100 && number < 170) {
        header_bottom_div.classList.add("active");
      } else {
        header.classList.remove("header--sticky");
        header_bottom_div.classList.remove("active");
      }
    }

    //To add and remove class for mobile header

    const mobileheader = document.getElementById("header-sticky-mobile");

    if (mobileheader !== null) {
      if (number >= 200) {
        mobileheader.classList.add("header--sticky");
      } else {
        mobileheader.classList.remove("header--sticky");
      }
    }
  };

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  let username = "";
  if (
    localparam !== "" &&
    localparam.decoded !== "" &&
    localparam.decoded.firstname !== null &&
    localparam.decoded.firstname !== ""
  ) {
    username = localparam.decoded.firstname.charAt(0) + localparam.decoded.lastname.charAt(0);
  }

  useEffect(() => {

    const throttledHandleStickyHeader = throttle(handleStickyHeader, 100);

    window.addEventListener("scroll", throttledHandleStickyHeader);
    ckeckLoginToken();

    return () => {
      window.removeEventListener("scroll", throttledHandleStickyHeader);
    }
  }, [ecomerce, username]);

  return (
    <header className={`header--desktop header--one ${classes}`} id="header-sticky">
      {note && note.homedetail && (
        <ModuleHeaderNotice note={note && note.homedetail ? note.homedetail.topbartitle : ""} />
      )}

      <div className="header__top">
        <div className="container">
          <div className="header__left">
            <Logo />
            <a href="#" className="header__top-toggle" onClick={(e) => handleShownav(e)}>
              <img src="/static/img/static_image/menu.png"></img>
            </a>
          </div>
          <div className="header__center">
            <div className="ps-header__search">
              <FormSearchHeader />
            </div>
          </div>
          <div className="header__right">
            <ModuleHeaderActions username={username} />
          </div>
        </div>
      </div>
      <div className={`header__bottom`} id="header_bottom_div">
        <NavigationPrimary />
      </div>
    </header>
  );
};

export default connect((state) => state)(HeaderDefault);
