import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import ProductRepository from "../../../api/ProductRepository";
import RatingList from "../../../pages/product/RatingList";

const WidgetShopByRating = () => {
  const [rating, setRating] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getProductRating();
    window.scrollTo(0, 0);
  }, [searchParams.get("rating")]);

  /*
    To get rating list
  */
  async function getProductRating() {
    const ratinglist = await ProductRepository.getProductRating();
    if (ratinglist) {
      setRating(ratinglist);
    } else {
      return null;
    }
  }

  return (
    <aside className="widget widget_shop widget_rating">
      <h3 className="widget-title">By Rating</h3>
      {rating && rating !== null && rating.length > 0 ? (
        <>
          {rating.map((item, index) => (
            <RatingList item={item} key={index} />
          ))}
        </>
      ) : (
        <RatingList />
      )}
    </aside>
  );
};

export default WidgetShopByRating;
