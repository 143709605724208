import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ breacrumb }) => {
  return (
    <ul className="breadcrumb">
      {breacrumb.map((item) => {
        if (!item.url) {
          return <li key={item.id}>{item.text}</li>;
        } else {
          return (
            <li key={item.text}>
              <Link to={item.url} key={item.id}>
                {item.text}
              </Link>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default BreadCrumb;
