/*
    To set product variant array
*/
export function VarientKeyName(productvariant) {
  let keyarray = [];
  var singlejson = JSON.parse(productvariant[0].variantjson);
  Object.keys(singlejson).forEach(function (key) {
    keyarray.push(key);
  });
  const setData = new Set(keyarray);
  keyarray = Array.from(setData);

  return keyarray;
}
/*
  To set product variant name
*/
export function VarientName(iskeyarray, selectedvariantname) {
  let variantname = "";
  if (iskeyarray && iskeyarray.length > 0) {
    if (selectedvariantname && selectedvariantname !== null && selectedvariantname !== "") {
      variantname = selectedvariantname;
    }
  }
  return variantname;
}