import parse from "html-react-parser";
import React, { useEffect, useState } from "react";

const ModuleProductDetailDescription = (product) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const shortDescriptionEl = document.getElementById("shortdescription");
    const shortDescriptionBtnEl = document.getElementById("shortdescriptionbtn");

    if (shortDescriptionEl && shortDescriptionBtnEl) {
      const textHeight = shortDescriptionEl.clientHeight;

      if (Number(textHeight) >= 75) {
        shortDescriptionBtnEl.style.display = "block";
      } else {
        shortDescriptionBtnEl.style.display = "none";
      }
    }
  }, [product]);

  /*
    To open review tab
  */
  const showMore = () => {
    setIsOpen(!isOpen);
    const box = document.querySelector(".box");

    if (box) {
      isOpen ? box.classList.add("open") : box.classList.remove("open");
    }
  };

  return (
    <div className="productshortdescription">
      {product.product.shortdescription && (
        <div className={`box ${isOpen ? "open" : ""}`}>
          <p className="text title-font shortdescription word-break-all" id="shortdescription">
            {parse(product.product.shortdescription)}
          </p>
          <a href="javascript:;" onClick={showMore} id="shortdescriptionbtn">
            <i className="arrow"></i>
          </a>
        </div>
      )}
    </div>
  );
};

export default ModuleProductDetailDescription;
