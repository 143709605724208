import React, { useEffect } from "react";

import BreadCrumb from "../../../components/elements/BreadCrumb";
import Container from "../../../components/layouts/Container";

const OrderFailed = () => {
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shop",
      url: "/shop",
    },
    {
      text: "Order Success",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container title="Order Succe">
      <div className="ps-page ps-page--inner form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Order Failed</h1>
          </div>
          <div className="ps-page__content ordersucess">
            <div className="row">
              <div className="col-md-6">
                <div data-show="true" className="ant-alert ant-alert-error" role="alert">
                  <span role="img" aria-label="close-circle" className="anticon anticon-close-circle ant-alert-icon">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="close-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                    </svg>
                  </span>
                  <div className="ant-alert-content">
                    <div className="ant-alert-message">
                      Payment failed. Your order has not placed. Please{" "}
                      <span>
                        <a href="/contactus" className="text-decoration-underline">
                          contact
                        </a>
                      </span>{" "}
                      store admin for more detail.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OrderFailed;
