import React from "react";

/*
  To import Swiper core and required modules
*/
import SwiperCore, { Navigation } from "swiper/core";
import { SwiperSlide } from "swiper/react";

import SwiperCarousel from "../components/elements/carousel/SwiperCarousel";
import Product from "../components/elements/products/Product";
import ProductGridWithDetail from "../components/elements/products/ProductGridWithDetail";
import ProductList from "../components/elements/products/ProductList";
import SkeletonProduct from "../components/elements/skeletons/SkeletonProduct";
import SkeletonProductHorizontal from "../components/elements/skeletons/SkeletonProductHorizontal";
import { generateTempArray } from "../utilities/common-helpers";
import NoDataFound from "../components/common/NoDataFound";
import ProductLayoutTwoOptionTwo from "../components/layouttwo/ProductLayoutTwoOptionTwo";
/*
  To install Swiper modules
*/
SwiperCore.use([Navigation]);

export default function useProductGroup() {
  return {
    withCarousel: (source, loading, setting) => {
      let carousel;
      if (!loading) {
        if (source && source.length > 0) {
          const items = source.map((item) => (
            <SwiperSlide key={item.id}>
              <Product product={item} />
            </SwiperSlide>
          ));

          carousel = <SwiperCarousel setting={setting ? setting : undefined}>{items}</SwiperCarousel>;
        } else {
          carousel = <p>No product(s) found.</p>;
        }
      } else {
        const skeletons = generateTempArray(2).map((item) => <div className=" col-6" key={item}></div>);
        carousel = <div className="row">{skeletons}</div>;
      }
      return carousel;
    },
    withGrid: (source, loading, columns = 5, currencyrate) => {
      let view;
      if (!loading) {
        if (source && Array.isArray(source) && source.length > 0) {
          const items = source.map((item) => (
            <div className="ps-layout__item card-box-shadow bn productlist" key={item.id}>
              <Product product={item} currencyrate={currencyrate} />
            </div>
          ));
          view = (
            <div className="ps-layout--grid ps-shop-items bn shop-items-list" data-columns={columns}>
              {items}
            </div>
          );
        } else {
          view = <NoDataFound component={"product"} />;
        }
      } else {
        const items = generateTempArray(columns * 2).map((item) => (
          <div key={item} className="ps-layout__item">
            <SkeletonProduct />
          </div>
        ));
        view = (
          <div className="ps-layout--grid ps-shop-items with-skeleton" data-columns={columns}>
            {items}
          </div>
        );
      }
      return view;
    },
    withGridDetail: (source, loading, columns = 5) => {
      let view;
      if (!loading) {
        if (source && source.length > 0) {
          const items = source.map((item) => (
            <div className="ps-layout__item" key={item.id}>
              <ProductGridWithDetail product={item} />
            </div>
          ));
          view = (
            <div className="ps-layout--grid ps-shop-items with-skeleton" data-columns={columns}>
              {items}
            </div>
          );
        } else {
          view = <NoDataFound component={"product"} />;
        }
      } else {
        const items = generateTempArray(columns * 2).map((item) => (
          <div key={item} className="ps-layout__item" data-columns={columns}>
            <SkeletonProduct />
          </div>
        ));
        view = (
          <div className="ps-layout--grid ps-shop-items with-skeleton" data-columns={columns}>
            {items}
          </div>
        );
      }
      return view;
    },
    withList: (source, loading, columns = 4, currencyrate) => {
      let view;
      if (!loading) {
        if (source && source.length > 0) {
          const items = source.map((item) => (
            <div className="ps-layout__item" key={item.id}>
              <ProductList product={item} />
            </div>
          ));
          view = <div className="ps-layout--list ps-shop-items">{items}</div>;
        } else {
          view = <p>No product found.</p>;
        }
      } else {
        const items = generateTempArray(columns).map((item) => (
          <div key={item} className="ps-layout__item" data-columns={columns}>
            <SkeletonProductHorizontal />
          </div>
        ));
        view = (
          <div className="ps-layout--list ps-shop-items with-skeleton" data-columns={columns}>
            {items}
          </div>
        );
      }
      return view;
    },
    layoutonewithGrid: (source, loading, columns = 5, currencyrate) => {
      let view;
      if (!loading) {
        if (source && Array.isArray(source) && source.length > 0) {
          const items = source.map((item) => (
            <div key={item.id} className={`col-6 col-md-6`} data-columns={columns}>
              {/* <ProductLayoutOneOptionOne product={item} currencyrate={currencyrate} /> */}
              {/* <ProductLayoutOneOptionTwo product={item} currencyrate={currencyrate} /> */}
            </div>
          ));
          view = (
            <div className="row p-0 m-0" >
              {items}
            </div>
          );
        } else {
          view = <NoDataFound component={"product"} />;
        }
      } else {
        const items = generateTempArray(columns * 2).map((item) => (
          <div key={item} className="ps-layout__item">
            <SkeletonProduct />
          </div>
        ));
        view = (
          <div className="" data-columns={1}>
            {items}
          </div>
        );
      }
      return view;
    },
    layouttwowithGrid: (source, loading, columns = 5, currencyrate) => {
      let view;
      if (!loading) {
        if (source && Array.isArray(source) && source.length > 0) {
          const items = source.map((item) => (
            <div key={item.id} className={`${columns == 3 ? "col-4" : "col-3"}`} data-columns={columns}>
              <ProductLayoutTwoOptionTwo product={item} currencyrate={currencyrate} />
            </div>
          ));
          view = (
            <div className="row" >
              {items}
            </div>
          );
        } else {
          view = <NoDataFound component={"product"} />;
        }
      } else {
        const items = generateTempArray(columns * 2).map((item) => (
          <div key={item} className="ps-layout__item">
            <SkeletonProduct />
          </div>
        ));
        view = (
          <div className="" data-columns={1}>
            {items}
          </div>
        );
      }
      return view;
    },
  };
}
