import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

const ModuleBlogTags = ({ blogtaglist, categoryId, tagId }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [selectedTag, setSelectedTag] = useState(searchParams.get('tagid') || tagId);

  useEffect(() => {
    setSelectedTag(searchParams.get('tagid') || tagId);
  }, [tagId]);

  const handleTagClick = async (tagId) => {
    if (selectedTag === tagId) {
      setSelectedTag("");
      searchParams.delete('tagid');
      if (!categoryId) {
        searchParams.delete('categoryid');
      }
      window.location.href = `/blogs?${searchParams.toString()}`;
    } else {
      setSelectedTag(tagId);
      navigate(`/blogs?categoryid=${categoryId || ''}&tagid=${tagId}`);
    }
  };


  return (<>
    {blogtaglist && blogtaglist !== null && blogtaglist !== "" && blogtaglist.length > 0 ? (<>
      <div className='blog-tags'>
        <div className='blog-tag-title mb-20'>
          <h3 className='text-tile'>Tags</h3>
        </div>
        <div className='blog-tags-badge '>
          {blogtaglist.map((item) =>
            <NavLink
              to={`/blogs?&categoryid=${categoryId || ''}&tagid=${item.id}`}
              className={`badge tags-badge mr-3 mb-2 ${selectedTag == item.id ? 'tag-select' : ''}`}
              onClick={() => handleTagClick(item.id)}
            >
              {item.tagsname}
            </NavLink>
          )}
        </div>
      </div>
    </>) : (<></>)
    }
  </>
  )
}

export default ModuleBlogTags