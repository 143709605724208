import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Filters from "./Filters";
import { UrlProduct } from "../../../pages/product/LoadProduct";

const WidgetShopFilterByBrands = ({ filtergroup }) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filtergroups, setfiltergroups] = useState([]);

  let filters = searchParams.get("filters");

  /*
    To toggle up or down
  */
  const showFilters = (filtergroupname) => {
    if (document.getElementById(filtergroupname)) {
      document.getElementById(filtergroupname).classList.toggle("fade");
      document.getElementById("up_" + filtergroupname).classList.toggle("fade");
      document.getElementById("down_" + filtergroupname).classList.toggle("fade");
    }
  };

  useEffect(() => {
    filtersUncheck();
  }, [filters]);

  useEffect(() => {
    resetFilters();
  }, [filters]);

  useEffect(() => {
    const filtersFromURL = searchParams.get('filters') ? searchParams.get('filters').split(',').map(Number) : [];
    setfiltergroups(filtersFromURL);
  }, [searchParams]);

  const resetFilters = () => {
    if (filters === null || filters === "") {
      filtergroup.forEach((filter) => {
        if (filter && filter !== null && filter !== "" && filter.filterlist !== null && filter !== "") {
          filter.filterlist.map((item) => {
            if (document.getElementById("filter_" + item.id)) {
              document.getElementById("filter_" + item.id).checked = false;
              document.getElementById("filter_" + item.id).setAttribute("checked", false);
            }
          });
        }
      });
      filters = "";
      setfiltergroups([]);
    }
  };

  const filtersUncheck = () => {
    if (filters) {
      var fg = [...filtergroups];
      for (var fid of filters.split(",")) {
        fg.push(Number(fid));
        setfiltergroups(fg);
        isChecked(fid);
      }
    }
  };

  const isChecked = (id) => {
    if (id) {
      if (document.getElementById("filter_" + id)) {
        document.getElementById("filter_" + id).checked = true;
        document.getElementById("filter_" + id).setAttribute("checked", true);
      }
    }
  };

  /*
    To set in filtergroup array and redirect
  */
  const filtergrouplist = (id, obj) => {
    id = Number(id);

    setfiltergroups(currentFilterGroups => {
      let updatedFilterGroups;
      if (obj) {
        updatedFilterGroups = currentFilterGroups.includes(id) ? [...currentFilterGroups] : [...currentFilterGroups, id];
      } else {
        updatedFilterGroups = currentFilterGroups.filter(filterId => filterId !== id);
      }

      redirectProduct(updatedFilterGroups);

      return updatedFilterGroups;
    });
  };

  const redirectProduct = (fg) => {
    const baseUrl = UrlProduct(searchParams, "filter");
    const filterParam = fg.length > 0 ? `&filters=${fg.join(',')}` : '';
    const fullUrl = `${baseUrl}${filterParam}`;
    navigate(fullUrl);
  };

  return (
    <aside className="widget widget_shop widget_filter-by-brands widget-filter">
      {filtergroup && filtergroup !== null && filtergroup !== "" && (
        <>
          {filtergroup.map((item) => (
            <>
              {item.filterlist && (
                <div className="wrapper">
                  <ul className="widget-title">
                    <li className="filtergroupli" key={item.id}>
                      <a
                        onClick={(e) => {
                          showFilters(item.filtergroupname);
                        }}
                      >
                        <div>
                          <span>{item.filtergroupname}</span>
                          <span className="pull-right openfilter " id={`up_${item.filtergroupname}`}>
                            <i className="fa fa-angle-up"></i>
                          </span>
                          <span className="pull-right openfilter fade" id={`down_${item.filtergroupname}`}>
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </a>

                      <div
                        id={item.filtergroupname}
                        className={
                          item && item.filterlist.length > 5 ? "openfilter filtergroup mt-40" : "openfilter  mt-40"
                        }
                      >
                        <Filters filter={item} onchangecheck={(id, flag) => filtergrouplist(id, flag)} />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ))}
        </>
      )}
    </aside>
  );
};

export default WidgetShopFilterByBrands;
