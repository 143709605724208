import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useLocalStorage from "../../hooks/useLocalStorage";

const Logout = () => {
  /*
    To set local storage blank
  */
  const { blankLocalStorage } = useLocalStorage();

  const navigate = useNavigate();

  useEffect(() => {
    blankLocalStorage();
    navigate("/login");
  }, []);
};
export default Logout;
