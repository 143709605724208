import { currencyOrdePrice } from "../../hooks/currencyCalculation";

const OrderSummary = ({ orderdetail, renderOrder }) => {
  /*
    To order summary views
  */
  let currencyrate = "";
  if (orderdetail && orderdetail.length > 0) {
    currencyrate = orderdetail[0].currencyForm;
  }
  const items = orderdetail.map((item) => (
    <div className="ps-cart__items order-summary bn">
      <div className="row">
        <div className="col-6 mb-10">
          <h3 className="ps-form__title pt-0">Summary</h3>
        </div>
      </div>
      {!renderOrder && (<>
        <div className="row">
          <div className="col-6 order-pading">
            <span className="ps-text--order order-summary-title">Order Status</span>
          </div>
          <div className="col-6 text-right order-pading">
            <strong className="badge badge-success order-title-font">{item.status}</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-6 order-pading">
            <span className="ps-text--order order-summary-title">Order Date</span>
          </div>
          <div className="col-6 text-right order-pading">
            <span className="ps-text--order cart-font-title">{item.orderdate}</span>
          </div>
        </div>
      </>
      )}
      <div className="row">
        <div className="col-6 order-pading">
          <span className="ps-text--order order-summary-title">Sub Total</span>
        </div>
        <div className="col-6 text-right order-pading">
          <span className="ps-text--order cart-font-title">{currencyOrdePrice(item.subtotal, currencyrate)}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-6 order-pading">
          <span className="ps-text--order order-summary-title">Shipping</span>
          {item.shippingflag !== null &&
            <span className="font-size-12 cart-font-title border rounded px-2 ml-1">{item.shippingflag === "standard" ? "Standard" : "Express"}</span>}
        </div>
        <div className="col-6 text-right order-pading">
          <span className="ps-text--order cart-font-title">{currencyOrdePrice(item.shipping, currencyrate)}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-6 order-pading">
          <span className="ps-text--order order-summary-title">Discount</span>
        </div>
        <div className="col-6 text-right order-pading">
          <span className="ps-text--order cart-font-title">{currencyOrdePrice(item.discount, currencyrate)}</span>
        </div>
      </div>
      {item.couponcode && (
        <div className="row">
          <div className="col-6 order-pading">
            <span className="ps-text--order order-summary-title">Coupon</span>
          </div>
          <div className="col-6 text-right order-pading title-font">
            <span className="ps-text--order cart-font-title">{item.couponcode}</span>
          </div>
        </div>
      )}

      <div className="row order-total ">
        <div className="col-6 pl-0 order-pading">
          <span className=" order-summary-title">Total</span>
        </div>
        <div className="col-6 text-right pr-0 order-pading">
          <span className="cart-font-title">{currencyOrdePrice(item.total, currencyrate)}</span>
        </div>
      </div>
    </div>
  ));
  return <>{items}</>;
};
export default OrderSummary;
