import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import config from '../../../config/config.json';
import ModuleProductActions from "./modules/ModuleProductActions";
import ModuleProductImages from "./modules/ModuleProductImages";
import useProductHook from "../../../hooks/useProductHook";
import ProductVariantModal from "../../shared/modals/ProductVariantModal";
import useProduct from "../../../hooks/useProduct";

const ComboProduct = ({ product, currencyrate, ecomerce }) => {
  const {
    quantity,
    isVarientobject,
    show,
    productItem,
    productStock,
    variantArray,
    groupedVariantArray,
    handleClose,
    handleAddItemToCart,
    handleIncreaseItemQty,
    handleDecreaseItemQty,
    addToCart,
    setSelectedVariant
  } = useProductHook(product, currencyrate, ecomerce);
  const { price, badges } = useProduct();

  return (
    <>
      <div className="ps-combo-items mb-15 mt-20">
        <div className="ps-layout__item card-box-shadow bn mt-10 p-0 mx-3" key={product.id}>
          <div className="ps-product ps-product--grid ">
            <div className="ps-product__thumbnail p-0 product-combo-image">
              <Link to={`/product/${product.uniqueid}`} className="ps-product__overlay"></Link>
              <ModuleProductImages product={product} />
              <ModuleProductActions product={product} />
              {badges(product)}
            </div>
            <div className="product-combo ml-15 mt-10 mb-10 ">
              <div>
                <h4 className="ps-product__title title-font">
                  <Link to={`/product/${product.uniqueid}`} className="producttitle combo-product-font">
                    {product.productname}
                  </Link>
                </h4>
              </div>
              <div className="mb-30">
                {price(product, currencyrate)}
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0 addto-cart-section">
              {productStock > 0 ? (
                <>
                  <div className="row mb-25 d-flex justify-content-between  text-center margin-horizontal-10 combo-add">
                    {config.isdispalayquantity === 0 ? (<>
                      <div className="col-md-7 col-lg-7 col-7 p-0 text-center">
                        <div className="form-group--number qty-btn">
                          <button className="up" onClick={(e) => handleIncreaseItemQty(e)}></button>
                          <button id="decrease-btn" className="down" onClick={(e) => handleDecreaseItemQty(e)}></button>
                          <input className="form-control" type="text" placeholder={quantity} disabled />
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5 col-5 p-0 text-center mb-10 combo-add">
                        <div className="col-12 p-0">
                          <a className="shop-btn shop-btn-fill-right pro-add-btn" onClick={(e) => handleAddItemToCart(e)}>
                            Add
                          </a>
                        </div>
                      </div>
                    </>) : (<>
                      <div className="col-12 mt-10">
                        <a className="shop-btn shop-btn-fill-right pro-add-btn col-md-12" onClick={(e) => handleAddItemToCart(e)}>
                          Add
                        </a>
                      </div>
                    </>)}
                  </div>
                </>
              ) : (<div className="col-md-12 col-lg-12 col-12 ">
                <p className="color-red outstock pt-5 pb-5 text-center">Sold Out</p>
              </div>)}
            </div>
            {/* {(disable || productStock < 1) && (
              <div className="col-md-12 col-lg-12 col-12 ">
                <p className="color-red outstock pt-5 pb-5 text-center">Sold Out</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <ProductVariantModal
        show={show}
        handleClose={handleClose}
        productItem={productItem}
        isVarientobject={isVarientobject}
        currencyrate={currencyrate}
        variantArray={variantArray}
        groupedVariantArray={groupedVariantArray}
        setSelectedVariant={setSelectedVariant}
        product={product}
        addToCart={addToCart}
      />
    </>
  );
};

export default connect((state) => state)(ComboProduct);
