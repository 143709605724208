import { Form } from "formik";
import React, { useEffect, useRef, useState } from "react";

import user from "../../api/user";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Logo from "../../components/elements/basic/Logo";
import FormButton from "../../components/elements/form/FormButton";
import FormikFrom from "../../components/elements/form/FormikFrom";
import Container from "../../components/layouts/Container";

/*
  To set otp form
*/
const otpform = {
  userid: localStorage.getItem("userid"),
  otp: "",
  event: localStorage.getItem("event"),
  contactno: "",
  emailid: "",
};

/*
  To set otp login json
*/
const otploginform = {
  otp: "",
  event: localStorage.getItem("event"),
  contactno: localStorage.getItem("SendOtpOn"),
};

/*
  To set initial values
*/
const initialValues = {
  first: "",
  secound: "",
  third: "",
  four: "",
};

const Otp = () => {
  const [timerCount, setTimer] = useState(30);
  const [disable, setDisable] = useState(false);
  const [userName, setUser] = useState([true]);
  const [errorMessage, setErrorMessage] = useState('');
  const [otpValues, setOtpValues] = useState({
    first: "",
    secound: "",
    third: "",
    four: ""
  });

  /*
    To start timer
  */
  const startTimer = () => {
    setTimer(30);
    countdown();
  };

  /*
    To timer initializer
  */
  const countdown = () => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
      if (localStorage.getItem("otpfrom") && localStorage.getItem("otpfrom") === "email") {
        setUser(localStorage.getItem("SendOtpOn"));
      } else {
        setUser(localStorage.getItem("phonecountry") + localStorage.getItem("SendOtpOn"));
      }

      countdown();
    }, 500);

    window.scrollTo(0, 0);
  }, []);

  /*
   To validate otp
 */
  const validateOtp = (otpValues) => {
    const { first, secound, third, four } = otpValues;
    if (
      /^[0-9]$/.test(first) &&
      /^[0-9]$/.test(secound) &&
      /^[0-9]$/.test(third) &&
      /^[0-9]$/.test(four)
    ) {
      return first + secound + third + four;
    } else {
      return null;
    }
  };

  /*
    To verify otp and login with otp
  */
  const verifyOtp = async () => {
    setDisable(true);
    const otp = validateOtp(otpValues);
    var otpdata = new Object();
    if (otp != null && otp != "") {
      if (localStorage.getItem("EventOtp") && localStorage.getItem("EventOtp") === "loginotp") {
        otpdata["contactno"] = localStorage.getItem("SendOtpOn");
        otpdata["userid"] = localStorage.getItem("userid");
        otpdata["event"] = localStorage.getItem("event");
        otpdata["otp"] = otp;
        otpdata["phonecountry"] = localStorage.getItem("phonecountry");
        const res = await user.loginOtp(otpdata);
        if (res.data.status === 200) {
          LocalStorage(res.data.payload);
          setTimeout(() => {
            setDisable(false);
            window.location.href = "/";
          }, 500);
        } else {
          if (otpValues === "") {
            setDisable(false);
            document.getElementById("errormsg").classList.remove("hide");
            document.getElementById("errormsg").innerHTML = "Please enter valid OTP";
          } else {
            setDisable(false);
            document.getElementById("errormsg").classList.remove("hide");
            document.getElementById("errormsg").innerHTML = res.data.errors.message;
          }
        }
      } else {
        if (otpform.emailid) {
          otpdata["emailid"] = otpform.emailid;
        }
        if (otpform.contactno) {
          otpdata["contactno"] = otpform.contactno;
        }
        otpdata["userid"] = otpform.userid;
        otpdata["event"] = localStorage.getItem("event");
        otpdata["otp"] = otp;

        const res = await user.verifyotp(otpdata);

        if (res.data.status === 200) {
          setTimeout(() => {
            setDisable(false);
            let event = localStorage.getItem("event");
            if (event && event === "Signin OTP") {
              loginWithOtp(otp);
            } else if (event && event === "Forget Password OTP") {
              window.location.href = "/setpassword";
            } else if (event && event === "Signup OTP") {
              loginWithOtp(otp);
            }
          }, 500);
        } else {
          setDisable(false);
          document.getElementById("errormsg").classList.remove("hide");
          document.getElementById("errormsg").innerHTML = res.data.errors.message;
        }
      }
    } else {
      if (Object.values(otpValues).every(value => /^[a-zA-Z]+$/.test(value))) {
        setDisable(false);
        document.getElementById("errormsg").innerHTML = "Invalid OTP.";
        document.getElementById("errormsg").classList.remove("hide");
      } else {
        setDisable(false);
        document.getElementById("errormsg").innerHTML = "Please enter valid OTP";
        document.getElementById("errormsg").classList.remove("hide");
      }
    }
  };

  /*
    To ref use for focus next field after enter otp
  */
  const firsttext_box = useRef(null);
  const secoundtext_box = useRef(null);
  const thridtext_box = useRef(null);
  const fourtext_box = useRef(null);

  /*
    To required fields
  */
  const onBlurHandler = (refInput) => {
    if (refInput.current?.value === "") {
      const fieldName = refInput.current.name;
      const msg = ('Field Is Required');
      setErrorMessage(msg);
    }
    else {
      setErrorMessage('');
    }
  }

  const showRefContent = (value, field) => {
    if (/^[a-zA-Z0-9]+$/.test(value)) {
      setOtpValues((prevValues) => ({ ...prevValues, [field]: value }));
      setErrorMessage("");
    } else {
      setErrorMessage("");
    }
  };

  /*
    To resend otp
  */
  const resendotp = async (data) => {
    if (localStorage.getItem("EventOtp") && localStorage.getItem("EventOtp") === "loginotp") {
      otpform.contactno = localStorage.getItem("SendOtpOn");
      otpform.event = localStorage.getItem("event");
      otpform.phonecountry = localStorage.getItem("phonecountry");

      const res = await user.sendOtpforLogin(otpform);
      if (res.data.status === 200) {
        startTimer();
        document.getElementById("errormsg").classList.add("hide");
        document.getElementById("errormsg").innerHTML = "";
      }
    } else {
      if (localStorage.getItem("SendOtp") === "emailid") {
        otpform.emailid = localStorage.getItem("SendOtpOn");
      } else {
        otpform.contactno = localStorage.getItem("SendOtpOn");
      }
      otpform.event = localStorage.getItem("event");

      var otpdata = new Object();
      if (otpform.emailid) {
        otpdata["emailid"] = otpform.emailid;
      }
      if (otpform.contactno) {
        otpdata["contactno"] = otpform.contactno;
      }
      otpdata["userid"] = otpform.userid;
      otpdata["event"] = otpform.event;
      otpdata["phonecountry"] = localStorage.getItem("phonecountry");

      const res = await user.resendotp(otpdata);
      if (res.data.status === 200) {
        startTimer();
        document.getElementById("errormsg").classList.add("hide");
        document.getElementById("errormsg").innerHTML = "";
      }
    }
  };

  /*
    To login with otp
  */
  const loginWithOtp = async (otp) => {
    var loginobject = new Object();
    loginobject["event"] = localStorage.getItem("SendOtp");
    loginobject["otp"] = otp;
    loginobject["userid"] = localStorage.getItem("userid");
    if (localStorage.getItem("SendOtp") === "emailid") {
      loginobject["emailid"] = localStorage.getItem("SendOtpOn");
    } else {
      loginobject["contactno"] = localStorage.getItem("SendOtpOn");
    }

    const res = await user.loginWithOtp(loginobject);

    if (res.data.status === 200) {
      LocalStorage(res.data.payload);
      window.location.href = "/";
    }
  };

  /*
    To set local storage
  */
  const LocalStorage = (data) => {
    localStorage.setItem("authToken", data.access_token);
    localStorage.setItem("logindate", new Date());
    localStorage.setItem("expires_in", data.expires_in);
    localStorage.setItem("refresh_token", data.refresh_token);
  };

  /*
    To focus on field
  */
  const handleClick = (reffer, e, currentRef) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      setOtpValues((prevValues) => ({ ...prevValues, [currentRef.current.name]: "" }));

      if (currentRef !== firsttext_box) {
        currentRef.current.previousSibling.focus();
        setOtpValues((prevValues) => ({ ...prevValues, [reffer.current.name]: "" }));
      }
    } else {
      reffer.current.focus();
    }
  };

  useEffect(() => {
    if (firsttext_box.current) {
      firsttext_box.current.focus();
    }
  }, []);

  return (
    <Container>
      <div className="ps-page form-loader">
        {disable ? (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        ) : (
          ""
        )}
        <GoogleCaptcha />
        <div className="container">
          <div className="ps-page__header"></div>
          <div className="ps-page__content ps-account ">
            <div className="row ml-0 mr-0 justify-content-center">
              <div className="content-card-login mt-50 mb-50 box-rounded-login login align-self-center">
                <div className="forgotpass-card-content">
                  <div className="br-1 login-welcome text-center login-mobile-hide ">
                    <div className="row height-100-per ">
                      <div className="col-12 ps-form--review form-padding">
                        <h3>Welcome To </h3>
                        <Logo />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="ps-form--review form-padding">
                      <FormikFrom
                        initialValues={initialValues}
                        onSubmit={(values) => {
                          verifyOtp(values);
                        }}
                      >
                        <Form>
                          <h2 className="ps-form__title">OTP Verification</h2>
                          <div className="row mt-20">
                            <div className="col-12 col-md-12">
                              <p>We have sent OTP on [{userName}]. Please enter OTP to proceed</p>
                            </div>
                          </div>
                          <div className="row mt-20">
                            <div className="col-3 col-md-3">
                              <input
                                className="form-control ps-form__input text-center"
                                type={"text"}
                                name={"first"}
                                value={otpValues.first}
                                maxLength={"1"}
                                returnKeyType={"next"}
                                ref={firsttext_box}
                                onChange={(e) => {
                                  showRefContent(e.target.value, "first");
                                }}
                                onBlur={() => onBlurHandler(firsttext_box)}

                                onKeyUp={(e) => {
                                  handleClick(secoundtext_box, e, firsttext_box);
                                }}
                              />
                            </div>
                            <div className="col-3 col-md-3">
                              <input
                                className="form-control ps-form__input text-center"
                                type={"text"}
                                name={"secound"}
                                value={otpValues.secound}
                                maxLength={"1"}
                                returnKeyType={"next"}
                                ref={secoundtext_box}
                                onChange={(e) => showRefContent(e.target.value, "secound")}
                                onBlur={() => onBlurHandler(secoundtext_box)}

                                onKeyUp={(e) => {
                                  handleClick(thridtext_box, e, secoundtext_box);
                                }}
                              />
                            </div>
                            <div className="col-3 col-md-3">
                              <input
                                className="form-control ps-form__input text-center"
                                type={"text"}
                                name={"third"}
                                value={otpValues.third}
                                maxLength={"1"}
                                returnKeyType={"next"}
                                ref={thridtext_box}
                                onChange={(e) => showRefContent(e.target.value, "third")}
                                onBlur={() => onBlurHandler(thridtext_box)}
                                onKeyUp={(e) => {
                                  handleClick(fourtext_box, e, thridtext_box);
                                }}

                              />
                            </div>
                            <div className="col-3 col-md-3">
                              <input
                                className="form-control ps-form__input text-center"
                                type={"text"}
                                name={"four"}
                                value={otpValues.four}
                                maxLength={"1"}
                                returnKeyType={"next"}
                                ref={fourtext_box}
                                onChange={(e) => showRefContent(e.target.value, "four")}
                                onKeyUp={(e) => {
                                  handleClick(fourtext_box, e, fourtext_box);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row mt-10">
                            <div className="col-12 col-md-8 text-left">
                              <span className="linktext">
                                {timerCount > 0 &&
                                  `Resend OTP in ${timerCount}
                                 Seconds`}
                              </span>
                            </div>
                            <div className="col-12 col-md-4 text-right">
                              <span>
                                {timerCount <= 0 && (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      resendotp();
                                    }}
                                  >
                                    Resend
                                  </a>
                                )}
                              </span>
                            </div>
                            <span className="color-red hide pl-15 pt-10" id="errormsg">
                            </span>
                          </div>
                          <div className="ps-form__submit mt-20">
                            <FormButton
                              name={"Verify"}
                              type="submit"
                              className={
                                disable
                                  ? "ps-btn ps-btn--warning width-100 disabled"
                                  : "ps-btn ps-btn--warning width-100"
                              }
                            />
                          </div>
                        </Form>
                      </FormikFrom>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Otp;
