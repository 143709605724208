import React from "react";

import shopcofig from "../../../../config/shopconfig.json";
const ModuleHeaderContactNumber = () => {
  return (
    <p className="ps-text--contact-number">
      Need help?{" "}
      <strong>
        <a href={`tel: ${shopcofig.shop_contactno}`}>{shopcofig.shop_contactno}</a>
      </strong>
    </p>
  );
};

export default ModuleHeaderContactNumber;
