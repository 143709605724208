import React from "react";

import Logo from "../../../../components/elements/basic/Logo";
import shopcofig from "../../../../config/shopconfig.json";
const ModuleFooterAddress = () => {
  return (
    <div className="ps-footer--address">
      <Logo />
      <div className="ps-footer__title">Our store</div>
      <p className="footer-font">
        {shopcofig.shop_address}
        {shopcofig.shop_city} {shopcofig.shop_pincode} {shopcofig.shop_state}, {shopcofig.shop_country}
      </p>
      <p>
        <a href={shopcofig.maplink} target="_blank" className="footer-font">
          Show on map
        </a>
      </p>
      <ul className="ps-social">
        {shopcofig.facebook ? (
          <li>
            <a className="ps-social__link facebook footer-icon" href={shopcofig.facebook}>
              <i className="fa fa-facebook"> </i>
              <span className="ps-tooltip">Facebook</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.instagram ? (
          <li>
            <a className="ps-social__link instagram footer-icon" href={shopcofig.instagram}>
              <i className="fa fa-instagram"></i>
              <span className="ps-tooltip">Instagram</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.youtube ? (
          <li>
            <a className="ps-social__link youtube footer-icon" href={shopcofig.youtube}>
              <i className="fa fa-youtube-play"></i>
              <span className="ps-tooltip">Youtube</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.pinterest ? (
          <li>
            <a className="ps-social__link pinterest footer-icon" href={shopcofig.pinterest}>
              <i className="fa fa-pinterest-p"></i>
              <span className="ps-tooltip">Pinterest</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.linkedin ? (
          <li>
            <a className="ps-social__link linkedin footer-font" href={shopcofig.linkedin}>
              <i className="fa fa-linkedin"></i>
              <span className="ps-tooltip">Linkedin</span>
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
};

export default ModuleFooterAddress;
