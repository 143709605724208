import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import BreadCrumb from '../../components/elements/BreadCrumb';
import Container from '../../components/layouts/Container';
import GoogleCaptcha from '../../components/common/GoogleCaptcha';
import ModuleBlogList from './modules/ModuleBlogList';
import ModuleBlogCategory from './modules/ModuleBlogCategory';
import ModuleBlogTags from './modules/ModuleBlogTags';
import useBlog from '../../hooks/useBlog';
import { useLocation } from 'react-router-dom';

const Blogs = () => {

  const { blogcategorylist, getBlogCategotyList } = useBlog();
  const { blogtaglist, getBlogTagList } = useBlog();
  const location = useLocation();

  const queryparams = new URLSearchParams(location.search);
  const categoryid = queryparams.get('categoryid');
  const tagid = queryparams.get('tagid');

  /*
  To breadcrumb
*/
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "Blog",
    }
  ]

  useEffect(() => {
    getBlogCategotyList();
    getBlogTagList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--blog">
          <div className="container">
            <div className="ps-page__header pb-0">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className='blogs'>
                <div className='row mt-10 custom-row'>
                  <div className='col-md-9'>
                    <ModuleBlogList categoryId={categoryid} tagId={tagid} />
                  </div>
                  <div className='col-md-3'>
                    <ModuleBlogCategory
                      blogcategorylist={blogcategorylist}
                      tagId={tagid}
                      categoryId={categoryid}
                    />
                    <ModuleBlogTags
                      blogtaglist={blogtaglist}
                      tagId={tagid}
                      categoryId={categoryid}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect((state) => state)(Blogs)