import React from "react";

import CustomPagination from "../../elements/basic/CustomPagination";
import ModuleShopActions from "../../partials/shop/modules/ModuleShopActions";

const Shop = ({ children, classes, fullwidth = false, actions = true, products, setIsMenu, isMenu, currencyrate }) => {
  /*
    To view 
  */
  let actionsView;
  if (actions) {
    actionsView = (
      <div className="ps-shop__header">
        <div className="container shopaction-container">
          <ModuleShopActions setIsMenu={setIsMenu} isMenu={isMenu} currencyrate={currencyrate} />
        </div>
      </div>
    );
  }
  if (!fullwidth) {
    return (
      <div className={`ps-shop ${classes}`}>
        {actionsView}
        <div className="ps-shop__content">{children}</div>
        <div className="ps-shop__footer">
          <CustomPagination products={products} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={`ps-shop ${classes !== undefined ? classes : ""}`}>
        {actionsView}
        <div className="ps-shop__content">
          <div className="container">{children}</div>
          <div className="ps-shop__footer">
            <CustomPagination products={products} />
          </div>
        </div>
      </div>
    );
  }
};

export default Shop;
