import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ModuleDetailMeta from "../../../components/elements/detail/modules/ModuleDetailMeta";
import ModuleDetailShoppingActions from "../../../components/elements/detail/modules/ModuleDetailShoppingActions";
import ModuleProductDetailSharing from "../../../components/elements/detail/modules/ModuleProductDetailSharing";
import config from "../../../config/config.json";
import useGetProducts from "../../../hooks/useGetProducts";
import useProduct from "../../../hooks/useProduct";
import SimilarProduct from "../../../pages/product/SimilarProduct";
import { VarientKeyName, VarientName } from "../../../pages/product/VariantKey";
import ModuleComboProduct from "./modules/ModuleComboProduct";
import ModuleDetailTabs from "./modules/ModuleDetailTabs";
import ModuleDetailThumbnail from "./modules/ModuleDetailThumbnail";
import ModuleDetailTopInformation from "./modules/ModuleDetailTopInformation";
import ModuleProductDetailDescription from "./modules/ModuleProductDetailDescription";
import ModuleDetailVariant from "./modules/ModuleDetailVariant";

const DetailDefault = ({
  product,
  comboproduct,
  likeproduct,
  currencyrate,
  productview = "detail",
  productvariant,
}) => {
  const { price } = useProduct();
  const { id } = useParams();
  const { productReview, getProductReviewById } = useGetProducts();

  const [iskeyarray, setKeyArray] = useState([]);
  const [isVariantflag, setVariantflag] = useState(false);
  const [variantArray, setVariantArray] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [isProductStatus, setProductStatus] = useState("out-stock");
  const [isProductStatusView, setProductStatusView] = useState(
    <p className="ps-product__log-status outstock">Sold Out</p>
  );

  let variantname = [];
  let checkedValue = "";


  /*
    To get variantname
  */
  const getRadios = () => {
    const allradios = document.getElementsByClassName(`radio_container_${product.id}`);

    for (let i = 0; i < allradios.length; i++) {
      const inputElements = allradios[i].querySelectorAll('input[type=radio]');
      inputElements.forEach(input => {
        if (input.checked) {
          checkedValue += input.value + "-";
        }
      });
    }
  }

  /*
    To set product variant array
  */
  const setVariantKey = () => {
    if (variantname === "") {
      if (productvariant && productvariant.length > 0) {
        setKeyArray(VarientKeyName(productvariant));
      }
    }
  };

  /*
    To make variant name by selected variant  
  */
  const setVariantName = () => {
    variantname = VarientName(iskeyarray, checkedValue);

    let result = "";
    let flag = false;
    if (variantname && variantname !== null && variantname !== "") {
      result = variantname.slice(0, -1);

      var j = 0;
      productvariant.map((item, index) => {
        if (item.variantname === result) {
          j = index;
          flag = true;
          setVariantflag(true);
        }
      });

      if (flag) {
        // UNCOMMENT IN NEXT RELEASE
        product["productname"] = productvariant[j].varianttitle;
        // product["description"] = productvariant[j].description;
        // product["shortdescription"] = productvariant[j].shortdescription;
        product["price"] = productvariant[j].price;
        product["offerprice"] = productvariant[j].offerprice;
        product["offertype"] = productvariant[j].offertype;
        product["variantid"] = productvariant[j].id;
        product["stock"] = productvariant[j].stock;
        product["hsn"] = productvariant[j].hsn;
        product["productvarientimagelist"] = productvariant[j].productimagelist;
        product["alreadyincart"] = productvariant[j].alreadyincart;
      } else {
        product["stock"] = 0;
      }
      setStatus();
    }
  };

  useEffect(() => {
    setVariantKey();
    getProductReviewById(id);
    setStatus();
    getRadios();
    setVarient();
    setVariantName();
  }, [id, productvariant, selectedVariant]);

  /*
    To set stock status 
  */
  const setStatus = () => {
    if (product && product.status === "unpublished") {
      setProductStatus("not-available");
      setProductStatusView(<p className="ps-product__log-status outstock">Not available</p>);
    } else if (product && Number(product.stock) > 0) {
      setProductStatus("in-stock");
      setProductStatusView(<p className="ps-product__log-status">in stock</p>);
    } else {
      setProductStatus("out-stock");
      setProductStatusView(<p className="ps-product__log-status outstock">Sold Out</p>);
    }
  };

  /*
    To set variant array for display
  */
  const setVarient = () => {
    if (productvariant && productvariant.length > 0) {
      const variantSet = new Set();

      productvariant.forEach((item) => {
        if (item.variantjson) {
          try {
            const variantjson = JSON.parse(item.variantjson);
            Object.keys(variantjson).forEach(key => {
              const variant = { name: key, value: variantjson[key] };
              variantSet.add(JSON.stringify(variant));
            });
          } catch (error) {
            console.error("Error parsing variantjson:", error);
          }
        }
      });

      const newVariantArray = Array.from(variantSet).map(item => JSON.parse(item));
      setVariantArray(newVariantArray);
    }
    else {
      setVariantflag(true);
    }
  };

  const groupedVariantArray = Object.values(
    variantArray.reduce((acc, { name, value }) => {
      if (!acc[name]) {
        acc[name] = { name, values: [] };
      }
      acc[name].values.push(value);
      return acc;
    }, {})
  );

  return (
    <>
      {product && (
        <>
          <div className="product--detail ps-product--detail mb-100">
            <div
              className={
                config.isslider === "false"
                  ? "ps-product__header product-padding"
                  : "ps-product__header product-padding image-gap"
              }
            >
              <div className="container-detail-product">
                <div className="col-12 sticky-component">
                  <ModuleDetailThumbnail product={product} productflag={isVariantflag} vertical={false} />
                </div>
                <div className={`col-5 main-component ${comboproduct && comboproduct.length > 0 ? 'combo-height' : 'nocombo-height'}`}>
                  <div className="ps-product__info">
                    {isProductStatusView}
                    <ModuleDetailTopInformation product={product} productReview={productReview} />
                    {price(product, currencyrate, "productdetail")}
                    <ModuleProductDetailDescription product={product} />
                    <div className="mt-30">
                      {groupedVariantArray.length > 0 && groupedVariantArray.map((item, index) => (
                        <div key={index} className="ps-product__variants">
                          <ModuleDetailVariant
                            array={variantArray}
                            setname={setSelectedVariant}
                            name={item.name}
                            label={item.name}
                            values={item.values}
                            productid={product.id}
                          />
                        </div>
                      ))}
                    </div>
                    {productview === "detail" && isProductStatus !== "out-stock" && isProductStatus !== "not-available" && (
                      <ModuleDetailShoppingActions
                        product={product}
                        productflag={isVariantflag}
                        productvariant={productvariant}
                      />
                    )}
                    {productview === "preview" && (
                      <ModuleDetailShoppingActions
                        product={product}
                        productflag={isVariantflag}
                        productvariant={productvariant}
                        productview={productview}
                      />
                    )}
                    <ModuleDetailMeta product={product} />
                    <ModuleProductDetailSharing product={product} />
                    <ModuleComboProduct comboproduct={comboproduct} currencyrate={currencyrate} />
                  </div>
                </div>
              </div>
            </div>
            <div className="ps-product__content ">
              <ModuleDetailTabs product={product} productReview={productReview} productview={productview} />
            </div>
          </div>
          {productview === "detail" && likeproduct && likeproduct !== null && likeproduct.length > 0 && (
            <div className="ps-product__content mb-100">
              <SimilarProduct likeproduct={likeproduct} currencyrate={currencyrate} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DetailDefault;
