import React from "react";

import NoDataFound from "../../components/common/NoDataFound";
import config from "../../config/config.json";
import OrderListItem from "./OrderListItem";

const OrderItemList = ({ orderItem }) => {
  /*
    To current order list views
  */
  let imagepath = config.apiUrl + config.image_path;

  const filteredItems = orderItem?.filter((item) =>
    ["Placed", "Payment Confirmed", "Processing", "Partially Shipped", "Shipped", "Canceled", "Return Requested", "Return approved", "Return Rejected", "Refund Failed"].includes(item.status)
  );

  if (!filteredItems || filteredItems.length === 0) {
    return <NoDataFound component={"order"} />;
  }

  return (
    <>
      {filteredItems.map((item) => (
        <OrderListItem key={item.id} item={item} />
      ))}
    </>
  );
};

export default OrderItemList;
