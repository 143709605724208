import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import useGetProducts from "../../../hooks/useGetProducts";
import SubMenuDropdown from "./SubMenuDropdown";

const Menu = ({ className }) => {
  const { mainmenu, getSiteMainMenu } = useGetProducts();

  useEffect(() => {
    getSiteMainMenu();
  }, []);

  /*
    To category view 
  */
  let sitemenulist = [];
  if (mainmenu && mainmenu.length > 0) {
    var ordecount = 1;
    mainmenu.map((items) => {
      if (items.type === "Category") {
        if (items.subcategories) {
          var class_name = "left-unset";
          if (Number(ordecount) > 5) {
            class_name = "";
          }

          sitemenulist.push(
            <li className="menu-item-has-children has-mega-menu" key={items.id}>
              <Link to={items.url} id={"menuid_" + items.id}>
                {items.menuname}
              </Link>
              {items.displaysubcategory === 1 ? (
                <div className={"mega-menu menu-card-box-shadow " + class_name} id={`mega_menu_` + items.id}>
                  <div className="mega-menu__wrapper max-width-unset" id={"mega_wrapper_" + items.id}>
                    <SubMenuDropdown source={items} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </li>
          );
        } else {
          sitemenulist.push(
            <li key={items.menuname}>
              <Link to={items.url} id={"menuid_" + items.id}>
                {items.menuname}
              </Link>
            </li>
          );
        }
      } else {
        sitemenulist.push(
          <li key={items.menuname}>
            <Link to={items.url} id={"menuid_" + items.id}>
              {items.menuname}
            </Link>
          </li>
        );
      }
      ordecount++;
    });
  }

  return <ul className={className}>{sitemenulist}</ul>;
};

export default Menu;
