import cart from "../../api/cart";

/*
  To special price discount
*/
const specialCalculation = async (product) => {
  if (product.isproductcoupon === "No") {
    var specialpriceobject = new Object();
    specialpriceobject["productid"] = product.productid;
    specialpriceobject["quantity"] = product.quantity;
    specialpriceobject["price"] = product.price;
    const res = await cart.specialPriceDiscount(specialpriceobject);
    if (res.data.status === 200) {
      if (res.data.payload !== null && res.data.payload !== "" && res.data.payload.isspecialprice !== "No") {
        product.discount = Number(res.data.payload.discount) * Number(product.quantity);
        var totalprice = Number(product.price);
        var newprice = Number(totalprice) - Number(res.data.payload.discount);
        if (Number(newprice) !== Number(totalprice)) {
          product.offerprice = newprice;
        }
      } else {
        product.offerprice = product.productofferprice;
      }
    }
  }

  return product;
};

export default {
  specialCalculation,
};
