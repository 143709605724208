import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { currencyOrdePrice } from "../../hooks/currencyCalculation";
import useProduct from "../../hooks/useProduct";

const OrderReturnProductList = ({ orderdetail, isallChecked, handelDisable, currencyrate }) => {
  const { thumbnailImages } = useProduct();
  const [isChecked, setIsChecked] = useState(0);

  let items = [];

  useEffect(() => {}, [isChecked]);

  /*
    To set return qty
  */
  const setReturnQty = (returnorderid, qty) => {
    if (orderdetail) {
      orderdetail.map((item) => {
        if (Number(item.id) === Number(returnorderid)) {
          if (Number(qty) > 0 && Number(item.quantity) >= Number(qty)) {
            item.returnedquantity = qty;
            handelDisable(false);
            document.getElementById("quntity_" + returnorderid).classList.add("hide");
          } else {
            handelDisable(true);
            document.getElementById("quntity_" + returnorderid).classList.remove("hide");
          }
        }
      });
    }
  };

  /*
    To select product for return
  */
  const checkProduct = (returnorderid, ischeck) => {
    setIsChecked(isChecked + 1);
    if (ischeck) {
      setIsProductCheck(returnorderid, 1);
      document.getElementById("errormsg").classList.add("hide");
      document.getElementById("errormsg").innerHTML = "";
    } else {
      setIsProductCheck(returnorderid, 0);
    }
  };

  /*
    To select product for return
  */
  const setIsProductCheck = (returnorderid, ischeck) => {
    if (orderdetail) {
      orderdetail.map((item) => {
        if (Number(item.id) === Number(returnorderid)) {
          item.isproductcheck = ischeck;
        }
      });
    }
  };

  /*
    To item list set in array
  */
  if (orderdetail) {
    orderdetail.map((item) => {
      if (!item.isreturned) {
        let productvariantjson = "";
        if (item.variantjson && item.variantjson !== null && item.variantjson !== "") {
          productvariantjson = JSON.parse(item.variantjson);
        }

        items.push(
          <tr key="1">
            <td className="text-center show">
              <div className="ps-form__group form-group mb-0 pt-15">
                <div className="form-check ml-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`return_` + item.id}
                    name={`return_` + item.id}
                    checked={Number(item.isproductcheck) === 1 ? true : false}
                    onChange={(e) => checkProduct(item.id, e.target.checked)}
                    value={"1"}
                  />
                  <label className="form-check-label" htmlFor={`return_` + item.id}></label>
                </div>
              </div>
            </td>
            <td className="text-left width-50-per">
              <div className="ps-product--on-cart simple">
                <div className="ps-product__thumbnail list-image pb-5">
                  <div className="ps-product__images">{thumbnailImages(item)}</div>
                </div>
                <div className="align-self-center">
                  <h4 className="ps-product__title">
                    <a href={`/product/${item.uniqueid}`}>{item.varianttitle ? item.varianttitle : item.producttitle}</a>
                  </h4>
                  <p className="font-size-11">
                    {productvariantjson ? (
                      <span>{item.varianthsn && `HSN: ${item.varianthsn}`}</span>
                    ) : (
                      <span>{item.hsn && `HSN: ${item.hsn}`}</span>
                    )}
                  </p>
                  <span>
                    {productvariantjson && (
                      <>
                        {Object.entries(productvariantjson).map(([key, value]) => (
                          <p className="font-size-11">{key} : {value}</p>
                        ))}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </td>
            <td className="vertical-align-middle color-blue text-center">
              <p className="color-blue">{item.quantity}</p>
            </td>
            <td className="vertical-align-middle color-blue text-center">
              <div className="row">
                <div className={"col-12"}>
                  <p className="productdiscountprice">{currencyOrdePrice(Number(item.price), currencyrate)}</p>
                </div>
              </div>
            </td>
            <td className="vertical-align-middle color-blue text-center">
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control ps-form__input"
                  placeholder="Quantity"
                  defaultValue={item.quantity}
                  onBlur={(e) => setReturnQty(item.id, e.target.value)}
                />
              </div>
              <span id={`quntity_` + item.id} className="hide color-red">
                Invalid Quantity
              </span>
            </td>
          </tr>
        );
      }
    });
  }

  return <>{items}</>;
};
export default connect((state) => state)(OrderReturnProductList);
