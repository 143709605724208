import React, { useEffect } from "react";
import { connect } from "react-redux";

import ProductOnCart from "../../../components/elements/products/ProductOnCart";
import { currencyPrice } from "../../../hooks/currencyCalculation";
import useEcomerce from "../../../hooks/useEcomerce";
import NoDataFound from "../../common/NoDataFound";

const ModuleEcomerceCartItems = ({ ecomerce, cartItems, cartevent = "", pecialPrice, handelcoupon, currencyrate }) => {
  const { increaseQty, decreaseQty, removeItem } = useEcomerce();

  useEffect(() => { }, [ecomerce, cartItems]);
  /*
    To remove item 
  */
  function handleRemoveItem(e, cartid) {
    e.preventDefault();
    removeItem({ cartid: cartid }, ecomerce.cartItems, "cart");
    localStorage.removeItem("appliedCoupon");
  }

  /*
    To increase item qty
  */
  const handleIncreaseItemQty = (e, cartid) => {
    e.preventDefault();
    increaseQty({ id: cartid }, cartItems);
  };

  /*
    To decrease item qty
  */
  function handleDecreaseItemQty(e, cartid) {
    e.preventDefault();
    decreaseQty({ id: cartid }, cartItems);
  }

  if (cartItems && cartItems.length > 0) {
    return (
      <>
        <table className="table ps-table ps-table--shopping-cart ps-table--responsive" id="cartlist">
          <thead>
            <tr>
              <th className="text-left padding-left-20">Items</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Total</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody className={(cartevent === "mycart" || cartevent === "shoppingcart") && "carttable"}>
            {cartItems.map((item, index) => (
              <tr key={item.id} className="pb-20">
                <td className="wishlist-td-productname text-left">
                  <ProductOnCart
                    product={item}
                    handelcoupon={handelcoupon}
                    pecialPrice={pecialPrice}
                    currencyrate={currencyrate}
                  />
                </td>
                <td
                  className={
                    cartevent === "mycart"
                      ? "text-center vertical-align-middle cartlist-quantity cart-quantity-td"
                      : "text-center vertical-align-middle cartlist-quantity"
                  }
                >
                  {cartevent === "mycart" ? (
                    <>
                      <span className="form-group--number color-blue">{item.quantity}</span>
                      {Number(item.stock) < Number(item.quantity) && (
                        <p className="color-red outstock text-center ">Sold Out</p>
                      )}
                    </>
                  ) : (
                    <>
                      <span className="form-group--number">
                        <button className="up" onClick={(e) => handleIncreaseItemQty(e, item.id)}></button>
                        <button
                          className={Number(item.quantity) <= 0 ? "down disable" : "down"}
                          onClick={(e) => handleDecreaseItemQty(e, item.id)}
                        ></button>
                        <input className="form-control" type="text" placeholder={item.quantity} disabled={true} />
                      </span>

                      {Number(item.stock) < Number(item.quantity) && (
                        <p className="color-red outstock text-center ">Sold Out</p>
                      )}
                    </>
                  )}
                </td>
                <td className="text-center vertical-align-middle ">
                  <strong className="text-center color-blue">
                    <span id={`productmain_price_${item.id}`}>
                      {Number(item.offerprice) > 0 ? (
                        <> {currencyPrice(Number(item.offerprice) * Number(item.quantity), currencyrate)}</>
                      ) : (
                        <> {currencyPrice(Number(item.price) * Number(item.quantity), currencyrate)}</>
                      )}
                    </span>
                  </strong>
                </td>
                <td className="text-center vertical-align-middle">
                  <div>
                    <a className="ps-icon ps-cart-item__remove" href="#" onClick={(e) => handleRemoveItem(e, item.id)}>
                      <i className="fa fa-trash mr-2 color-red"></i>
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  } else {
    return <NoDataFound component={"cart"} />;
  }
};

export default connect((state) => state)(ModuleEcomerceCartItems);
